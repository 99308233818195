import React, { useContext } from 'react';
import {TrendData} from "../../pages/NewDashBoard/components/Context/TimeLine";

import AreaCard from "./AreaCard";
import LineCard from "./LineCard";
import LineBarCard from "./LineBarCard";

import classes from './ChartCards.module.scss';

const AreaCards = () => {
    const cardItems = [];
    const lineItems = [];
    const lineBarItems = [];

    const timezone = localStorage.getItem("serverTimezone")
        ? localStorage.getItem("serverTimezone")
        : "Australia/Brisbane";

    const trendData = useContext(TrendData);
    const { analyticData } = trendData;
    const { entireSet, lineSet, lineBarSet } = analyticData;

    // Field for area graph
    Object.keys(entireSet).forEach((key) => {
        let { label, data, description } = entireSet[key];
        cardItems.push({
            label, data, description
        });
    });

    // Fields for line chart
    Object.keys(lineSet).forEach((key) => {
        let { label, data, description } = lineSet[key];
        lineItems.push({
            label, data, description,
        });
    });

    // Field for LineBar chart
    Object.keys(lineBarSet).forEach((key) => {
        let { label, data, description, label_bar, label_line } = lineBarSet[key];
        let { barData, lineData } = data;
        lineBarItems.push({
            label, data, description, barData, lineData, label_bar, label_line
        })
    });

    return (
        <div className={classes.cards_wrapper}>
            <div className={classes.cards}>
                {cardItems.map((item, index) => (
                    <AreaCard key={index} item={item} timezone={timezone}/>
                ))}
                {lineItems.map((item, index) => (
                    <LineCard key={index} item={item} timezone={timezone} />
                ))}
                {lineBarItems.map((item, index) => (
                  <LineBarCard key={index} item={item} />
                ))}
            </div>
        </div>
    )

};

export default AreaCards;