// noinspection JSUnresolvedFunction

import React, {useContext} from "react";
import {
    ComposedChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Area,
    Tooltip,
    CartesianGrid,
    Label,
} from "recharts";

import { TimeManager} from "../../pages/NewDashBoard/components/Context/TimeLine";
import { timeStringFormat, getTimeLabel } from "../functions/backtrackTime";

// Import dayJS module to convert time between timezones
import dayjs from "dayjs";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const CustomTooltip = (props) => {
    if (!props || !props.payload) {
        return null;
    }

    let payload;

    if (props.payload[0]) {
        payload = props.payload[0].value;
    }

    return (
        <div
            style={{
                background: "rgba(0, 0, 0, 0.85)",
                padding: "1em",
                textAlign: "center",
                borderRadius: "1em"
            }}
        >
            {props.label}
            <br/>
            Value: {payload}
        </div>
    );
};

/**
 * Function to compare 2 Date X and Y. Return True if they are the same date, False otherwise.
 * @param {*} X : The date of the selected day.
 * @param {*} Y : The current date
 * @returns  Boolean - True if X and Y is the same date, False otherwise
 */
const compareDate = (X, Y) => {
    // Set hours, minutes, seconds and milliseconds to 0
    const selectedDate = X.hour(0).minute(0).second(0).millisecond(0);
    // selectedDate = selectedDate.hour(0).minute(0).second(0).millisecond(0);
    const currentDate = Y.hour(0).minute(0).second(0).millisecond(0);
    return (selectedDate.diff(currentDate) === 0);
}

const AreaChart = ({data, noLabel, timezone}) => {
    // Timeline Manager Context
    // const timelineManager = useContext(TimeManager);
    // Get the date setting function
    // const {range} = timelineManager;
    const serverTZ = timezone;
    const {value: currentTime} = useContext(TimeManager);
    // Variables to hold length of the current data and the data content.
    let dataLength = 0;
    let dataArray = [];

    // Deconstruct the object into an array of values
    for (let key in data) {
        dataArray.push(data[key]);
        dataLength += 1;
    }
    const marginStyling = {
        top: 15,
        left: 30,
        right: 30,
        bottom: 15,
    };

    const labelStyling = {
        textAnchor: "middle",
        fill: "rgb(255, 255, 255)",
        fontWeight: "bold",
        fontSize: "medium",
    };

    if (!data || !dataLength) {
        return null;
    }
    const dataSet = [];

    // Maximum number of intervals displayed on the graph.
    // If the selectedDate is currentDate, shows up to currentTime
    // Else, shows full data for that day.

    let todayTime = dayjs(new Date()).tz();
    let selectedDate = todayTime.hour(7).minute(0).second(0).millisecond(0);
    let residual = dayjs(new Date()).tz(serverTZ).minute() > 30 ? 0.5 : 0;
    let currTime = dayjs(new Date()).tz(timezone).hour();
    let maxInterval = compareDate(selectedDate, dayjs(new Date()).tz(serverTZ)) ? (currTime - 7 + residual) * 2 : 48;
    const graphInterval = Math.round(maxInterval / 5);

    for (let index = 0; index <= maxInterval; index++) {
        // Get the format time for each index
        const adjustedTime = getTimeLabel(index, dayjs().tz(serverTZ), serverTZ);
        const timeString = timeStringFormat(adjustedTime, "dayjs");
        dataSet.push({
            time: index,
            timeString: timeString,
            value: dataArray[index],
        });
    }
    return (
        <div style={{width: "100%", height: "100%"}}>
            <ResponsiveContainer width={"99%"} height={"100%"}>
                <ComposedChart margin={marginStyling} data={dataSet}>
                    <defs>
                        <linearGradient id="AreaGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            {/*Light Blue*/}
                            <stop offset="0%" stopColor="#217ee2"/>
                            {/*Dark Blue*/}
                            <stop offset="50%" stopColor="#0f3661"/>
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray={"3 3"} stroke={"rgba(76, 76, 76, 0.3)"}/>
                    <XAxis dataKey={"timeString"}
                           interval={graphInterval}
                           tick={true}
                           dy={15}
                    />
                    <YAxis tick={true} dataKey={"value"}>
                        <Label
                            position={"insideLeft"}
                            angle={-90}
                            style={labelStyling}
                        />
                    </YAxis>
                    <Area
                        type="monotone"
                        dataKey="value"
                        fillOpacity={0.15}
                        isAnimationActive={false}
                        fill="url(#AreaGradient)"
                        stroke="url(#AreaGradient)"
                        strokeWidth={4}

                    />
                    <Tooltip content={<CustomTooltip time={currentTime} timezone={serverTZ}/>}/>
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default AreaChart;
