import React, { useCallback, useContext, useRef } from "react";
import {Timeline, TimeManager, TrendData} from "../Context/TimeLine";
import infoIconSVG from "../../../../../src/images/info-dashboard.svg";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import classes from "./CurrentButton.module.scss";
import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);

const InfoTooltip = ({ children, message }) => {
    const tooltipMessageWrapper = useRef(null);

    const setTooltipWrapper = useCallback(node => {
        tooltipMessageWrapper.current = node;
    }, [])


    return (
        <div className={classes.tooltip}>
            <div className={classes.tooltip_content}>
                {children}
            </div>
            <div className={classes.tooltip_message}>
                <span ref={setTooltipWrapper}>
                    {message}
                </span>
            </div>
        </div>
    )
}

const CurrentButton = () => {
    const trendData = useContext(TrendData);
    const { value: currentTime } = useContext(TimeManager);
    const { playTime, seekToLast } = useContext(Timeline);
    const { lastEmitTime } = trendData;

    const onNowClick = useCallback(seekToLast, [seekToLast]);
    const serverTZ = localStorage.getItem('serverTimezone') ? localStorage.getItem('serverTimezone') :
        "Australia/Brisbane";
    const lastFetchTime = dayjs(lastEmitTime).tz(serverTZ).format('HH:mm:ss');

    const buttonLiveClass = playTime === currentTime ? classes.on_current : null;
        //Matomo Tracker
        const { trackEvent } = useMatomo()
        const handleOnClick = () => {
            // Track click on button
            trackEvent({ category: 'Button-Clicks', action: 'change-time', name: 'Live Button'})
        }
    return (
        <div className={classes.component_stack}>
            <div className={`${classes.now_btn} ${buttonLiveClass}`} onClick={() => { 
                onNowClick();
                handleOnClick();
             }}>
                <span>Live</span>
            </div>
            <div className={classes.info_btn}>
                <InfoTooltip message={
                    <div>
                        <span>Last updated time:</span>
                        <br />
                        <span>{lastFetchTime} - {serverTZ}</span>
                    </div>

                }>
                    <img src={infoIconSVG}
                         alt={"Information Icon"} />
                </InfoTooltip>
            </div>
        </div>
    )
};


export default CurrentButton;