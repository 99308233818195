// Delta in percentage
const getDelta = (today, yesterday, time) => {
    let deltaSet = {};

    const checkables = Object.keys(today).filter(e => {
        return today[e].viewable;
    })


    checkables.forEach(x => {

        // console.log(x, "--DATA--");
        const { data : todayData } = today[x];
        // console.log(todayData, "todayData");
        const { data : yesterdayData } = yesterday[x];
        // console.log(yesterdayData, "yesterday data");

        deltaSet[x] = 100 * (todayData[time] - yesterdayData[time]) / yesterdayData[time];
        if (isNaN(deltaSet[x])) {
            deltaSet[x] = 0;
        } else {
            deltaSet[x] = Math.max(-100, Math.min(100, deltaSet[x]));
        }
    });

    return deltaSet;
};

export const getDeltaSet = (today, yesterday, timeIndex) => {
    const { data : todayData } = today;
    const { data : yesterdayData } = yesterday;

    if (timeIndex >= todayData.length) {
        return NaN;
    };

    let delta = 100 * (todayData[timeIndex] - yesterdayData[timeIndex]) 
                    / yesterdayData[timeIndex];

    if (isNaN(delta)) {
        delta = 0;
    } else {
        delta = Math.max(-100, Math.min(100, delta));
    };

    return delta;
    
}

export default getDelta;