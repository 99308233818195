class Random {
    /**
     * @see https://jsfiddle.net/jniac/fmx8bz9y/
     * @param {Array<Number>} points represents vertices in Triangle 
     * @param {Number} size cardinality of produced random tuples
     */
    static generateWithinTriangle(points, size) {
        let a = points[0];
        let b = points[1];
        let c = points[2];
        let pointsArray = [];

        for (let index = 0; index < size; index++) {
            let p = {};
            let r1 = Math.random();
            let r2 = Math.random();

            if (r1 + r2 > 1) {
                r1 = 1 - r1;
                r2 = 1 - r2;
            }
            p.x = a[0] + (b[0] - a[0]) * r1 + (c[0] - a[0]) * r2;
            p.y = a[1] + (b[1] - a[1]) * r1 + (c[1] - a[1]) * r2;
            p.value = ~~(Math.random() * 15);
            pointsArray.push(p);
        }
        

        return pointsArray;
    }

    /** A function to generate uniform random distribution  
     *  between [a, b]
     * @param a starting range
     * @param b ending range 
    */
    static valueBetween(a, b) {
        return ~~(
            this.valueBetweenPrecise(a, b)
        )
    }

    static valueBetweenPrecise(a, b) {
        return (
            Math.random() * (b - a) + a
        )
    }

    static generateBetween(a, b, size) {
        const points = [];
        for (let index = 0; index < size; index++) {
            points.push(
                this.valueBetween(a, b)
            )
        }
        return points;
    }

    /**
     * Generates random dataset by following the probability distribution 
     * list for each of the corresponding elements in the array.
     * @param Array probabilityList The List containing all the 
    *                  probability distribution for each element
     * @param {Number} size The total output elements
     */

    static generateDataSet(probabilityDistribution, size) {
        const total = probabilityDistribution.reduce(
            (accumulator, value) => {
                return accumulator + value;
            }
        );
        if (total !== 1) {
            throw new Error("The distribution must add upto 1.")
        };

        const dataset = [];


        for (let lastTotalDistribution = 0, index = 0; 
                index < probabilityDistribution.length; 
                index++) {
            if (index === probabilityDistribution.length - 1) {
                dataset.push(size - lastTotalDistribution);
                break ;
            };

            const currentProbability = probabilityDistribution[index];
            const currentDistribution = Math.round(currentProbability * size)

            lastTotalDistribution += currentDistribution;
            
            dataset.push(currentDistribution);

        };

        return dataset;

    };
}

export default Random;