import React, 
{ createContext, useState, useEffect, useRef, useContext, useCallback, memo } 
from 'react';
// Import random class
import Random from "../../../../components/functions/Random";
// Context to reference the time viewed by the user
export const Timeline = createContext();
// Context to provide trend data possibly in future
// interacts with database
export const TrendData = createContext();
// Context to inform what is the current time
// Used by both TrendData and Timeline context
export const TimeManager = createContext();
// Context used by warnings page
export const WarningData = createContext();
export const TIME = {
    INITIAL : 0,
    UPDATE : 1
};

const START_TIME = {
    VISIT: 22.51,
    PEDESTRIAN: 6.14,
    SHOPPERS: 45.13
}

const START_COUNT = {
    TOTAL: 122939,
    PEDESTRIAN: 422918,
    SHOPPERS: 105728
}

/** A current user can be either returning or new user
*   usually returning users are always greater than new user
*   Around 75-85% of the users are returning users and rest of them are new
*   So new users and returning users are accumulations i.e. sums
*   over time.
*   Current User is the trend or total detected users in the mall.
**/
const customDistribution = x => {
    // Test only
    if (x <= 9) {
        // Around 9 intervals (7 till 11)
        return Random.valueBetween(150, 150*1.5);
    } else if (x > 9 && x <= 12) {
        // Around 11 till 
        return Random.valueBetween(200, 200 * 1.25);
    } else if (x > 12 && x <= 16) {
        // Around 11 till 2:30 there is a slight bump
        return Random.valueBetween(300, 300*1.5);
    } else if (x > 16 && x <= 20) {
        // Around 2:30 till 4:30 there is an even taller bump
        return Random.valueBetween(400, 400 * 1.1);
    } else if (x > 20 && x <= 24) {
        // Around 4:30 till 
        return Random.valueBetween(500, 550 * 1.15);
    } else if (x > 24 && x <= 28) {
        return Random.valueBetween(600, 600 * 1.1);
    } else if (x > 28 && x <= 32) {
        return Random.valueBetween(600, 620 * 1.15);        
    } else if (x > 32 && x <= 36) {
        return Random.valueBetween(500, 500 * 1.15);
    } else if (x > 36 && x <= 40) {
        return Random.valueBetween(300, 300 * 1.25);
    } else if (x > 40 && x <= 44) {
        return Random.valueBetween(200, 200 * 1.05);
    }
    return Random.valueBetween(100, 100 * 1.2);
}

// const customDwellDistribution = x => {
//     // At first the dwell time must be 20-30
//     // from 7 am till 8:30 am
//     if (x < 4) {
//         return Random.valueBetween(10, 15);
//     } else if (x < 7) {
//         return Random.valueBetween(30, 35);
//     } else if (x < 12) {
//         return Random.valueBetween(40, 60);
//     } else if (x < 15) {
//         return Random.valueBetween(45, 60);
//     } else if (x < 20) {
//         return Random.valueBetween(40, 45);
//     } else if (x < 24) {
//         return Random.valueBetween(30, 35);
//     } else if (x < 28) {
//         return Random.valueBetween(20, 25);
//     } else if (x < 30) {
//         return Random.valueBetween(5, 10);
//     } else if (x < 36) {
//         return Random.valueBetween(0, 2);
//     }
//
//     return Random.valueBetween(0, 10);
// }

const customLoyalPercentageDistribution = x => {
    if (x < 6) {
        return Random.valueBetweenPrecise(0.7, 0.9);
    } else if (x < 14) {
        return Random.valueBetweenPrecise(0.5, 0.7);
    } else if (x < 16) {
        return Random.valueBetweenPrecise(0.2, 0.5);
    } else if (x < 24) {
        return Random.valueBetweenPrecise(0.5, 0.7)
    }
    return Random.valueBetweenPrecise(0.85, 0.99);
};

const customShopperDistribution = x => {
    if (x < 6) {
        // 7am till 9:30am
        return Random.valueBetweenPrecise(0.1, 0.2);
    } else if (x < 14) {
        // Till 12:30pm
        return Random.valueBetweenPrecise(0.3, 0.35);
    } else if (x < 16) {
        return Random.valueBetweenPrecise(0.35, 0.4);
    } else if (x < 18) {
        return Random.valueBetweenPrecise(0.4, 0.55);
    } else if (x < 21) {
        // Till 3:30 pm
        return Random.valueBetweenPrecise(0.5, 0.75);
    } else if (x < 26) {
        // Till 5 : 30pm
        return Random.valueBetweenPrecise(0.3, 0.45);
    } else if (x < 31) {
        // Till 
        return Random.valueBetweenPrecise(0.25, 0.3);
    }
    return Random.valueBetweenPrecise(0.1, 0.15);
};

const customSSPDistribution = x => {
    // Test only
    if (x <= 9) {
        // Around 9 intervals (7 till 11)
        return Random.valueBetween(400, 400*1.5);
    } else if (x > 9 && x <= 12) {
        // Around 11 till 
        return Random.valueBetween(600, 600 * 1.25);
    } else if (x > 12 && x <= 16) {
        // Around 11 till 2:30 there is a slight bump
        return Random.valueBetween(800, 800*1.5);
    } else if (x > 16 && x <= 20) {
        // Around 2:30 till 4:30 there is an even taller bump
        return Random.valueBetween(600, 600 * 1.1);
    } else if (x > 20 && x <= 24) {
        // Around 4:30 till 
        return Random.valueBetween(700, 750* 1.15);
    } else if (x > 24 && x <= 28) {
        return Random.valueBetween(700, 750 * 1.1);
    } else if (x > 28 && x <= 32) {
        return Random.valueBetween(600, 620 * 1.15);        
    } else if (x > 32 && x <= 36) {
        return Random.valueBetween(450, 500 * 1.15);
    } else if (x > 36 && x <= 40) {
        return Random.valueBetween(300, 400 * 1.25);
    } else if (x > 40 && x <= 44) {
        return Random.valueBetween(200, 200 * 1.05);
    }
    return Random.valueBetween(100, 100 * 1.2);
}

const customerGrowthRateWithSalesDistribution = x => {
    // Test only
    if (x <= 9) {
        // Around 9 intervals (7 till 11)
        return Random.valueBetween(400, 400*1.5);
    } else if (x > 9 && x <= 12) {
        // Around 11 till 
        return Random.valueBetween(600, 600 * 1.25);
    } else if (x > 12 && x <= 16) {
        // Around 11 till 2:30 there is a slight bump
        return Random.valueBetween(800, 800*1.5);
    } else if (x > 16 && x <= 20) {
        // Around 2:30 till 4:30 there is an even taller bump
        return Random.valueBetween(600, 600 * 1.1);
    } else if (x > 20 && x <= 24) {
        // Around 4:30 till 
        return Random.valueBetween(700, 750* 1.15);
    } else if (x > 24 && x <= 28) {
        return Random.valueBetween(700, 750 * 1.1);
    } else if (x > 28 && x <= 32) {
        return Random.valueBetween(600, 620 * 1.15);        
    } else if (x > 32 && x <= 36) {
        return Random.valueBetween(450, 500 * 1.15);
    } else if (x > 36 && x <= 40) {
        return Random.valueBetween(300, 400 * 1.25);
    } else if (x > 40 && x <= 44) {
        return Random.valueBetween(200, 200 * 1.05);
    }
    return Random.valueBetween(100, 100 * 1.2);
}

const customerGrowthRateDistribution = x => {
    // Test only
    if (x <= 9) {
        // Around 9 intervals (7 till 11)
        return Random.valueBetween(400, 400*1.5);
    } else if (x > 9 && x <= 12) {
        // Around 11 till 
        return Random.valueBetween(600, 600 * 1.25);
    } else if (x > 12 && x <= 16) {
        // Around 11 till 2:30 there is a slight bump
        return Random.valueBetween(800, 800*1.5);
    } else if (x > 16 && x <= 20) {
        // Around 2:30 till 4:30 there is an even taller bump
        return Random.valueBetween(600, 600 * 1.1);
    } else if (x > 20 && x <= 24) {
        // Around 4:30 till 
        return Random.valueBetween(700, 750* 1.15);
    } else if (x > 24 && x <= 28) {
        return Random.valueBetween(700, 750 * 1.1);
    } else if (x > 28 && x <= 32) {
        return Random.valueBetween(600, 620 * 1.15);        
    } else if (x > 32 && x <= 36) {
        return Random.valueBetween(450, 500 * 1.15);
    } else if (x > 36 && x <= 40) {
        return Random.valueBetween(300, 400 * 1.25);
    } else if (x > 40 && x <= 44) {
        return Random.valueBetween(200, 200 * 1.05);
    }
    return Random.valueBetween(100, 100 * 1.2);
}

const customerChurnRateDistribution = x => {
    // Test only
    if (x <= 9) {
        // Around 9 intervals (7 till 11)
        return Random.valueBetween(400, 400*1.5);
    } else if (x > 9 && x <= 12) {
        // Around 11 till 
        return Random.valueBetween(600, 600 * 1.25);
    } else if (x > 12 && x <= 16) {
        // Around 11 till 2:30 there is a slight bump
        return Random.valueBetween(800, 800*1.5);
    } else if (x > 16 && x <= 20) {
        // Around 2:30 till 4:30 there is an even taller bump
        return Random.valueBetween(600, 600 * 1.1);
    } else if (x > 20 && x <= 24) {
        // Around 4:30 till 
        return Random.valueBetween(700, 750* 1.15);
    } else if (x > 24 && x <= 28) {
        return Random.valueBetween(700, 750 * 1.1);
    } else if (x > 28 && x <= 32) {
        return Random.valueBetween(600, 620 * 1.15);        
    } else if (x > 32 && x <= 36) {
        return Random.valueBetween(450, 500 * 1.15);
    } else if (x > 36 && x <= 40) {
        return Random.valueBetween(300, 400 * 1.25);
    } else if (x > 40 && x <= 44) {
        return Random.valueBetween(200, 200 * 1.05);
    }
    return Random.valueBetween(100, 100 * 1.2);
}

const customerSalesvsVisitorsDistribution = x => {
    // Test only
    if (x <= 9) {
        // Around 9 intervals (7 till 11)
        return Random.valueBetween(400, 400*1.5);
    } else if (x > 9 && x <= 12) {
        // Around 11 till 
        return Random.valueBetween(600, 600 * 1.25);
    } else if (x > 12 && x <= 16) {
        // Around 11 till 2:30 there is a slight bump
        return Random.valueBetween(800, 800*1.5);
    } else if (x > 16 && x <= 20) {
        // Around 2:30 till 4:30 there is an even taller bump
        return Random.valueBetween(600, 600 * 1.1);
    } else if (x > 20 && x <= 24) {
        // Around 4:30 till 
        return Random.valueBetween(700, 750* 1.15);
    } else if (x > 24 && x <= 28) {
        return Random.valueBetween(700, 750 * 1.1);
    } else if (x > 28 && x <= 32) {
        return Random.valueBetween(600, 620 * 1.15);        
    } else if (x > 32 && x <= 36) {
        return Random.valueBetween(450, 500 * 1.15);
    } else if (x > 36 && x <= 40) {
        return Random.valueBetween(300, 400 * 1.25);
    } else if (x > 40 && x <= 44) {
        return Random.valueBetween(200, 200 * 1.05);
    }
    return Random.valueBetween(100, 100 * 1.2);
}

const customerSalesvsDwellTime = x => {
    // Test only
    if (x <= 9) {
        // Around 9 intervals (7 till 11)
        return Random.valueBetween(400, 400*1.5);
    } else if (x > 9 && x <= 12) {
        // Around 11 till 
        return Random.valueBetween(600, 600 * 1.25);
    } else if (x > 12 && x <= 16) {
        // Around 11 till 2:30 there is a slight bump
        return Random.valueBetween(800, 800*1.5);
    } else if (x > 16 && x <= 20) {
        // Around 2:30 till 4:30 there is an even taller bump
        return Random.valueBetween(600, 600 * 1.1);
    } else if (x > 20 && x <= 24) {
        // Around 4:30 till 
        return Random.valueBetween(700, 750* 1.15);
    } else if (x > 24 && x <= 28) {
        return Random.valueBetween(700, 750 * 1.1);
    } else if (x > 28 && x <= 32) {
        return Random.valueBetween(600, 620 * 1.15);        
    } else if (x > 32 && x <= 36) {
        return Random.valueBetween(450, 500 * 1.15);
    } else if (x > 36 && x <= 40) {
        return Random.valueBetween(300, 400 * 1.25);
    } else if (x > 40 && x <= 44) {
        return Random.valueBetween(200, 200 * 1.05);
    }
    return Random.valueBetween(100, 100 * 1.2);
}

// START generating datasets

const generateDataset = (distribution) => {
    let entireSet = [];
    for (let index = 0; index < 48; index++) {
        entireSet.push(
            distribution(index + 1)
        )
    }
    return entireSet;
}

// const generateDwellTime = () => {
//     return generateDataset(customDwellDistribution);
// }

const generateLoyalPercentage = (dataset) => {
    const percentageRatio = generateDataset(customLoyalPercentageDistribution);
    return dataset.map((e, i) => {
        return Math.round((percentageRatio[i] * e));
    });
};

const generateShopperPercentage = () => {
    return generateDataset(customShopperDistribution);
};

const generateCurrentSet = () => {
    return generateDataset(customDistribution);
};

const generateSPP = () => {
    return generateDataset(customSSPDistribution);
};

const generateCustomerGrowthRateWithSales = () => {
    return generateDataset(customerGrowthRateWithSalesDistribution);
};

const generateCustomerGrowthRate = () => {
    return generateDataset(customerGrowthRateDistribution);
};

const generateCustomerChurnRate = () => {
    return generateDataset(customerChurnRateDistribution);
};

const generateSalesvsVisitors = () => {
    return generateDataset(customerSalesvsVisitorsDistribution);
};

const generateSalesvsDwellTime= () => {
    return generateDataset(customerSalesvsDwellTime);
};

const generateMostPerformingDays = () => {
    return [
        {
            day : "Thursday", value : Random.valueBetween(1200, 1300),
        }, {
            day : "Wednesday", value : Random.valueBetween(900, 1200),

        }, {
            day : "Tuesday", value : Random.valueBetween(800, 850),
        }, {
            day : "Monday", value : Random.valueBetween(760, 800),
        }
    ]
};

// const generateTotalSet = dataSet => {
//     let currentTotal = Random.valueBetween(7000, 10000);
//     let totalSet = [];
//     dataSet.forEach(data => {
//         currentTotal += ~~(data / 4);
//         totalSet.push(currentTotal);
//     });
//     return totalSet;
// };

/**
 * Function to divide the current user into either
 * returning or existing user.
 * Around 75-85% of the time the current user is going to be
 * returning user and rest must be existing user.
 * @param dataSet Data of the current users 
 */
const findUserType = dataSet => {
    let newUserCollection = [],
        returningUserCollection = [];

    dataSet.forEach(data => {
        // 75 - 85 % it is going to be returning user
        let lowerRange = Random.valueBetweenPrecise(0.75, 0.8);
        let upperRange = lowerRange + 0.05;
        let percentageReturn = Random.valueBetweenPrecise(lowerRange, upperRange);
        let returningUser = Math.round(data * percentageReturn);
        
        newUserCollection.push(
            data - returningUser
        );
        returningUserCollection.push(
            returningUser
        );
    })

    return {
        newUsers : newUserCollection,
        returningUsers : returningUserCollection
    };
}

/**
 * Splits dataset based on random ratio generated between lower and upper range (inspired from findUserType())
 * @param dataset dataset values used as boundary
 * @param lowerRange lower ratio range
 * @param upperRange upper ratio range
 * @returns {{lowerRatio: *[], upperRatio: *[]}} upper and lower split arrays, from dataset
 */
const userDataSplit = (dataset, lowerRange=0.75, upperRange=0.85) => {
    const upperRatio = [], lowerRatio = [];

    dataset.forEach(data => {
        // random range between lower and upper
        const upperRatioSplit = Random.valueBetweenPrecise(lowerRange, upperRange);
        const upperValue = Math.round(data * upperRatioSplit);
        // push upper and lower ratio values into allocated arrays
        upperRatio.push(upperValue);
        lowerRatio.push(data - upperValue);
    })
    return {upperRatio, lowerRatio};
}

/**
 * Increments visiting counts with random increments between minRandom and maxRandom
 * @param initialValue starting visit count value
 * @param minRandom min random value to increment by
 * @param maxRandom max random value to increment by
 * @returns {*[]} array of 48 values, each incremented from 1st value (7am)
 */
const generateIncrementalValues = (initialValue, minRandom, maxRandom) => {
    const entireSet = [initialValue];

    for (let index = 0; index < 47; index++) {
        // loop through 47 for the remaining times between 7 a.m. and 7 p.m.
        const value = entireSet[index] + Random.valueBetween(minRandom, maxRandom);
        entireSet.push(value);
    }
    return entireSet;
}

/**
 * Fluctuating average duration values
 */
const generateAverageVisitsDuration = (initialValue, minRandom, maxRandom) => {
   const entireSet = [initialValue];
    for (let index = 0; index < 47; index++) {
        // loop through 47 for the remaining times between 7 a.m. and 7 p.m.
        const range = Random.valueBetweenPrecise(minRandom, maxRandom);
        const value = entireSet[index] - range;
        entireSet.push(Math.round(value * 100) / 100);
    }
    return entireSet;
}

const adjustedTime = (hour, minute) => {
    
    let fractionHour = hour + minute / 60;
    let adjustedHour = (fractionHour - 7) % 24;

    if (adjustedHour < 0) {
            adjustedHour = 24 + adjustedHour;
    }

    let total30Mins = adjustedHour;
    let floor = Math.floor(adjustedHour);
    let ceil = Math.ceil(adjustedHour);

    let midRange = (floor + ceil) / 2;

    if (total30Mins > midRange) {
            total30Mins = midRange;
    } else if (total30Mins < midRange) {
            total30Mins = floor;
    }

    return 2 * total30Mins;
}

export const TimeManagerProvider = ({ children }) => {
    // State to store current time
    const currentTime = new Date();
    const [adjustedTimeValue, setAdjustedTime] = useState({
        value : adjustedTime(
            currentTime.getHours(),
            currentTime.getMinutes()
        ),
        state : TIME.INITIAL
    });

    useEffect(() => {
        // On mount start interval checking for new time update
        setInterval(() => {
            // Get the updated value from adjusted time
            setAdjustedTime(currentAdjustedTime => {
                let currentTime = new Date();
                let nextAdjustedTime = adjustedTime(
                    currentTime.getHours(),
                    currentTime.getMinutes()
                );
                if (currentAdjustedTime.value !== nextAdjustedTime) {
                    return {
                        value : nextAdjustedTime,
                        state : TIME.UPDATE
                    }
                }
                return currentAdjustedTime;
            })
        }, 1000);
    }, []);

    const sharedState = {
        state : adjustedTimeValue.state,
        value : adjustedTimeValue.value
    };

    return (
        <TimeManager.Provider value={ sharedState }>
            { children }
        </TimeManager.Provider>
    );
}

export const TrendDataProvider = memo(({ children }) => {
    const currentTime = useContext(TimeManager);
    const today = useRef(null);
    const yesterday = useRef(null);
    const loaded = useRef(false);
    const [dataSet, setDataSet] = useState({
        today : null,
        yesterday : null,
    });

    const updateShowFlag = useCallback(index => {
        let { currentUsers : dataSet } = today.current;
        dataSet.forEach((element, position) => {
            element.show = position <= index;
        })
    }, []);

    
    useEffect(() => {
        switch (currentTime.state) {
            case TIME.UPDATE:
                // Here only change the paint flag
                break;
                
            default:
                if (loaded.current) {
                    break;
                } else {
                    loaded.current = true;
                }

                // -- Yesterday -- //
                // - Visiting Now = CurrentPedestrians (U) + CurrentShoppers (L)
                const yesterdayCurrentUserSet = generateCurrentSet();
                const { upperRatio: yesterdayPedestrians, lowerRatio: yesterdayShoppers } =
                    userDataSplit(yesterdayCurrentUserSet, 0.66, 0.75);
                // - Visited Today >= Returning Visitors (U) + New Visitors (L)
                const yesterdayUserSet = generateIncrementalValues(760,
                    80, 350);
                const { upperRatio: yesterdayReturningUsers,
                    lowerRatio: yesterdayNewUsers } = userDataSplit(yesterdayUserSet);
                // - Total Visitors, Visits by Pedestrians/Shoppers
                const yesterdayTotalUserSet =
                    generateIncrementalValues(START_COUNT.TOTAL - 10000, 200, 300);
                const yesterdayTotalVisitsByPedestrian =
                    generateIncrementalValues(START_COUNT.PEDESTRIAN - 10000, 200, 500);
                const yesterdayTotalVisitsByShoppers =
                    generateIncrementalValues(START_COUNT.SHOPPERS - 1000, 80, 200);
                // - Average Visit, by Shopper/Pedestrian Duration
                const yesterdayDwellTime =
                    generateAverageVisitsDuration(START_TIME.VISIT, -0.02, 0.02);
                const yesterdayAvgPedestrianVisitDuration =
                    generateAverageVisitsDuration(START_TIME.PEDESTRIAN, -0.02, 0.02);
                const yesterdayAvgShoppersVisitDuration =
                    generateAverageVisitsDuration(START_TIME.SHOPPERS, -0.02, 0.02);
                // -- END -- //

                // -- Today -- //
                // - Visiting Now = CurrentPedestrians (U) + CurrentShoppers (L)
                const todayCurrentUserSet = generateCurrentSet();
                const { upperRatio: todayPedestrians, lowerRatio: todayShoppers } =
                    userDataSplit(todayCurrentUserSet, 0.66, 0.75);
                // - Visited Today >= Returning Visitors (U) + New Visitors (L)
                const todayUserSet = generateIncrementalValues(760, 80, 350);
                const { upperRatio: todayReturningUsers,
                    lowerRatio: todayNewUsers} = userDataSplit(todayUserSet);
                // - Total Visitors, Visits by Pedestrians/Shoppers
                const todayTotalUserSet =
                    generateIncrementalValues(START_COUNT.TOTAL, 80, 200);
                const totalVisitsByPedestrian =
                    generateIncrementalValues(START_COUNT.PEDESTRIAN, 200, 500);
                const totalVisitsByShoppers =
                    generateIncrementalValues(START_COUNT.SHOPPERS, 80, 200);
                // - Average Visit, by Shopper/Pedestrian Duration
                const todayDwellTime =
                    generateAverageVisitsDuration(START_TIME.VISIT, -0.02, 0.02);
                const avgPedestrianVisitDuration =
                    generateAverageVisitsDuration(START_TIME.PEDESTRIAN, -0.02, 0.02);
                const avgShoppersVisitDuration =
                    generateAverageVisitsDuration(START_TIME.SHOPPERS, -0.02, 0.02);
                // -- END -- //

                const mostPerformingDays = generateMostPerformingDays();
                const todayShoppingPercentage = generateShopperPercentage();
                const todayUserType = findUserType(todayUserSet);
                const todayLoyalVisitorsPercentage = 
                                    generateLoyalPercentage(todayUserType.returningUsers);
                const SPP = generateSPP();
                const customerGrowthRateWithSales = generateCustomerGrowthRateWithSales();
                const customerGrowthRate = generateCustomerGrowthRate();
                const customerChurnRate = generateCustomerChurnRate();
                const customerSalesvsVisitors = generateSalesvsVisitors();
                const SalesVsDwellTime = generateSalesvsDwellTime();

                today.current = {
                    todayUsers : {
                        label : "Visited Today",
                        data: todayUserSet,
                        unit: 'people',
                        tooltip : true,
                        graph : true,
                        inHome : true,
                        viewable : true,
                        cardTooltip: "Number of total visitors at your selected time",
                        description : "Total number of people that have visited your precinct today, including new and returning visitors." 
                    },
                    dwellTime : {
                        label : "Avg. Visit Duration (All-Time)",
                        data : todayDwellTime,
                        tooltip : false,
                        graph : false,
                        unit : "mins",
                        inHome : true,
                        viewable : true,
                        cardTooltip: "Average time visitors are spending before entering a shop or leaving at your selected time",
                        description : "The average amount of time your visitors are spending in your precinct before leaving."
                    },
                    currentUsers : {
                        label : "Visiting Now",
                        data : todayCurrentUserSet,
                        tooltip : true,
                        graph : true,
                        inHome : true,
                        viewable : true,
                        unit: "people",
                        cardTooltip: "Number of current visitors at your selected time",
                        description : "The number of visitors that are currently in the store."
                    },
                    newUsers : {
                        label : "New Visitors Today",
                        data : todayNewUsers,
                        tooltip : false,
                        graph : true,
                        inHome : true,
                        viewable : true,
                        unit: "people",
                        cardTooltip: "Number of visitors never that have visited before, at your selected time",
                        description : "The total number of visitors which have not visited your store before."
                    },
                    returningUsers : {
                        label : "Returning Visitors Today",
                        data : todayReturningUsers,
                        tooltip : false,
                        graph : true,
                        inHome : true,
                        viewable : true,
                        unit: "people",
                        cardTooltip: "Number of visitors returning after 3 days or more at your selected time",
                        description : "The total numbers of visitors which have visited your store before."
                    },
                    totalUsers : {
                        label : "Total Visitors (All-Time)",
                        data : todayTotalUserSet,
                        graph : true,
                        inHome : true,
                        viewable : true,
                        unit: "people",
                        cardTooltip: "Number of visitors since the installation of SPACE Pin at your selected time",
                        description : "The total number of visitors visited your store so far since the implementation."
                    },

                    // -- Newly added, for replication --
                    shoppers: {
                        label: "Current Shoppers",
                        label_bar: "Shoppers",
                        label_line: "Mins",
                        data: todayShoppers,
                        graph: true,
                        inHome: true,
                        viewable: true,
                        unit: "people",
                        cardTooltip: "Number of visitors staying at your precinct and its stores",
                        description: "Number of visitors staying at your precinct and its stores",
                    },
                    pedestrians: {
                        label: "Current Pedestrians",
                        label_bar: "Pedestrians",
                        label_line: "Mins",
                        data: todayPedestrians,
                        graph: true,
                        inHome: true,
                        viewable: true,
                        unit: "people",
                        cardTooltip: "Number of visitors passing through your precinct",
                        description: "Number of visitors passing through your precinct",
                    },
                    totalPedestrianVisitCount: {
                        label: "Total Visits by Pedestrians (All-Time)",
                        data: totalVisitsByPedestrian,
                        graph: false,
                        inHome: true,
                        viewable: true,
                        unit: "",
                        cardTooltip: "Total number of visits by pedestrians",
                        description: "The total number of visit counts by pedestrians since the installation",
                    },
                    totalShopperVisitCount: {
                        label: "Total Visits by Shoppers (All-Time)",
                        data: totalVisitsByShoppers,
                        graph: false,
                        inHome: true,
                        viewable: true,
                        unit: "",
                        cardTooltip: "Total number of visits by pedestrians",
                        description: "The total number of visit counts by shoppers since the installation",
                    },
                    averagePedestrian: {
                        label: "Avg. Pedestrian Visit Duration (All-Time)",
                        data: avgPedestrianVisitDuration,
                        graph: false,
                        inHome: true,
                        viewable: true,
                        unit: "mins",
                        cardTooltip: "The average time a pedestrian spends before leaving",
                        description: "The average time a pedestrian spends before leaving",
                    },
                    averageShopper: {
                        label: "Avg. Shopper Visit Duration (All-Time)",
                        data: avgShoppersVisitDuration,
                        graph: false,
                        inHome: true,
                        viewable: true,
                        unit: "mins",
                        cardTooltip: "The average time a shopper spends before entering a shop or leaving",
                        description: "The average time a shopper spends before entering a shop or leaving",
                    },
                    // -- END --

                    // below are stats, not used for home cards
                    shoppersPercentage : {
                        label : "Shoppers",
                        data : todayShoppingPercentage,
                    },
                    loyalVisitors : {
                        label : "Loyal Visitors 💗",
                        description : "The total number of visitors which are visiting your store frequently.",
                        data : todayLoyalVisitorsPercentage
                    },
                    mostPerformingDays : {
                        label : "Most Performing Days",
                        description : "The days with the highest number of new and returning visitors to your precinct.",
                        data : mostPerformingDays
                    },
                    SPP: {
                        label : "Sales Per Person",
                        description : "Sales Per Person (SPP) is your sales divided by the total number of visitors.",
                        data : SPP
                    },
                    CustomerGrowthRateWithSales: {
                        label : "Customer Growth Rate with Sales",
                        description : "The rate that your customer size is growing with the number of sales you had. ",
                        data : customerGrowthRateWithSales
                    },
                    CustomerGrowthRate: {
                        label : "Customer Growth Rate",
                        description : "The rate that your customer size is growing you had.",
                        data : customerGrowthRate
                    },
                    CustomerChurnRate: {
                        label : "Customer Churn Rate",
                        description : "The rate of the customers not returning to your precinct for more than 18 days.",
                        data : customerChurnRate
                    },
                    SalesVsVisitors: {
                        label : "Sales vs. Visitors",
                        description : "The total amount of your sales and the total number of visitors.",
                        data : customerSalesvsVisitors
                    },
                        SalesVsDwellTime: {
                        label : "Sales vs. Dwell Time",
                        description : "The total amount of your sales and the total number of visitors this week.",
                        data : SalesVsDwellTime
                    },
                };

                yesterday.current = {
                    todayUsers : {
                        label : "Today Visitors",
                        data: yesterdayUserSet,
                        tooltip : true,
                        graph : true,
                    },
                    dwellTime : {
                        label : "Dwell Time",
                        data : yesterdayDwellTime,
                        tooltip : false,
                        graph : false
                    },
                    currentUsers : {
                        label : "Current Visitors",
                        data : yesterdayCurrentUserSet,
                        tooltip : true,
                        graph : true
                    },
                    totalUsers : {
                        label : "Total Visitors (All-Time)",
                        data : yesterdayTotalUserSet,
                        cardTooltip : "All Time",
                        graph : true
                    },
                    newUsers : {
                        label : "New Visitors Today",
                        data : yesterdayNewUsers,
                        tooltip : false,
                        graph : true
                    },
                    returningUsers : {
                        label : "Returning Visitors Today",
                        data : yesterdayReturningUsers,
                        tooltip : false,
                        graph : true
                    },

                    // Newly added, for replication (YESTERDAY)
                    shoppers: {
                        label: "Shoppers",
                        data: yesterdayShoppers,
                        graph: true,
                    },
                    pedestrians: {
                        label: "Pedestrians",
                        data: yesterdayPedestrians,
                        graph: true,
                    },
                    totalPedestrianVisitCount: {
                        label: "Total Visits by Pedestrians (All-Time)",
                        data: yesterdayTotalVisitsByPedestrian,
                        graph: true,
                    },
                    totalShopperVisitCount: {
                        label: "Total Visits by Shoppers (All-Time)",
                        data: yesterdayTotalVisitsByShoppers,
                        graph: true,
                    },
                    averagePedestrian: {
                        label: "Avg. Pedestrian Visit Duration",
                        data: yesterdayAvgPedestrianVisitDuration,
                        graph: true,
                    },
                    averageShopper: {
                        label: "Avg. Shopper Visit Duration",
                        data: yesterdayAvgShoppersVisitDuration,
                        graph: true,
                    },
                };
            break;
        }
        
        // Data set for today and yesterday
        setDataSet({
            today : today.current,
            yesterday : yesterday.current,
            analyticData : {
                entireSet : {
                    todayVisitors : today.current.todayUsers,
                    currentVisitors: today.current.currentUsers,
                    newVisitors : today.current.newUsers,
                    returningVisitors : today.current.returningUsers,
                },
                lineSet: {
                    totalUser: today.current.totalUsers,
                    totalPedestrianVisitCount: today.current.totalPedestrianVisitCount,
                    totalShopperVisitCount: today.current.totalShopperVisitCount,
                    averageTimeSpent: today.current.dwellTime,
                    averagePedestrian: today.current.averagePedestrian,
                    averageShopper: today.current.averageShopper
                },
                lineBarSet: {
                    averagePedestrianVsPedestrian: {
                        ...today.current.pedestrians,
                        data: {
                            barData: today.current.pedestrians.data,
                            lineData: today.current.averagePedestrian.data
                        }
                    },
                    averageShopperVsShopper: {
                        ...today.current.shoppers,
                        data: {
                            barData: today.current.shoppers.data,
                            lineData: today.current.averageShopper.data
                        }
                    }
                },
                aggregatedSet : {
                    mostPerformingDays : today.current.mostPerformingDays
                }
            },
            calculatedData : {
                AreaChartSet : {
                    SPP: today.current.SPP,
                    CustomerChurnRate: today.current.CustomerChurnRate,
                    
                },
                LineBarChartSet : {
                    CustomerGrowthRateWithSales: today.current.CustomerGrowthRateWithSales,
                    SalesVsVisitors: today.current.SalesVsVisitors,
                    SalesVsDwellTime: today.current.SalesVsDwellTime
                },
                BarChartSet : {
                    CustomerGrowthRate: today.current.CustomerGrowthRate,
                }
            }
        });

    }, [currentTime, updateShowFlag])
    

    if (!dataSet.today || !dataSet.yesterday) {
        return null;
    }

    return (
        <TrendData.Provider value={ dataSet }>
            { children }
        </TrendData.Provider>
    )
});

export const TimelineProvider = ({ children }) => {
    const timeManager = useContext(TimeManager);
    const [isPlaying, setPlaying] = useState(false);
    const [time, setTime] = useState(timeManager.value);
    const intervalID = useRef(null);
    const maxTime = useRef(timeManager.value);

    

    let shared = {
        playing : isPlaying,
        playTime : time,
        togglePlaying : () => {
            setPlaying(playingState => !playingState);
        },
        setPlayTime : index => {
            setTime(index);
        },
        seekToLast : () => {
            setTime(maxTime.current);
        }
    };


    useEffect(() => {
        // Here listen for isPlaying and take the logic forward
        if (isPlaying) {
            intervalID.current = setInterval(() => {
                setTime(lastCurrentTime => {
                    // If the entire duration is played then set the playing state to 
                    // be paused
                    if (lastCurrentTime === maxTime.current) {
                        // This enables loop around
                        return 0;
                    }
                    return (lastCurrentTime + 1) % 48;
                });
            }, 1000);
        } else if (intervalID.current) {
            clearInterval(intervalID.current);
        }
    }, [isPlaying]);

    useEffect(() => {
        maxTime.current = timeManager.value;
    }, [timeManager]);

    return (
        <Timeline.Provider value={ shared }>
            { children }
        </Timeline.Provider>
    )
}

/**
 * Custom Distribution for Warnings page
 */

const customTotalUsers = x => {
    // Test only
   if (x <= 5) {
       // Around 9 intervals (7 till 11)
       return Random.valueBetween(20, 30);
   } else if (x > 5 && x <= 7) {
       return Random.valueBetween(40, 50)
   } else if (x > 7 && x <= 9) {
       return Random.valueBetween(50, 65);
   } else if (x > 9 && x <= 12) {
       // Around 11 till 
       return Random.valueBetween(70, 75);
   } else if (x > 12 && x <= 16) {
       // Around 11 till 2:30 there is a slight bump
       return Random.valueBetween(100, 120);
   } else if (x > 16 && x <= 20) {
       // Around 2:30 till 4:30 there is an even taller bump
       return Random.valueBetween(120, 130);
   } else if (x > 20 && x <= 22) {
       // Around 4:30 till 
       return Random.valueBetween(120, 125);
   } else if (x > 22 && x <= 24) {
       return Random.valueBetween(80, 90);
   } else if (x > 24 && x <= 32) {
       return Random.valueBetween(40, 80);        
   } else if (x > 32 && x <= 36) {
       return Random.valueBetween(30, 40);
   } else if (x > 36 && x <= 40) {
       return Random.valueBetween(20, 30);
   } else if (x > 40 && x <= 44) {
       return Random.valueBetween(10, 20);
   }
       
   return Random.valueBetween(100, 100 * 1.2);
}

const customSDFunctional = (dataset) => {
    let sdFunctional = [];

    dataset.forEach(data => {
        let factor = Random.valueBetweenPrecise(0.1, 0.25);
        sdFunctional.push(
            ~~(data * factor)
        );
    })
    return sdFunctional;
}

const customSDIncidents = dataset => {
    let sdIncidents = [];
    dataset.forEach(data => {
        let factor = Random.valueBetweenPrecise(0.04, 0.09);
        sdIncidents.push(
            ~~(data * factor)
        )
    });
    return sdIncidents;
}

const customOverCrowdedArea = dataset => {
    let areas = [];
    dataset.forEach(data => {
        let factor = Random.valueBetweenPrecise(0.075, 0.1);
        areas.push(
            ~~(data * factor)
        )
    })

    return areas;
}

const totalSDIncidents = dataset => {
    let accumulations = [];
    let total = 0;
    dataset.forEach(data => {
        total += data;
        accumulations.push(total);
    });

    return accumulations;
}

const generateTotalWarnings = () => {
   let entireSet = [];
   for (let index = 0; index < 48; index++) {
       entireSet.push(
           customTotalUsers(
               index + 1
           )
       )
   }
   return entireSet;
}

const generateNoIncident = (dataset, incident) => {
    let noIncident = [];
    dataset.forEach((data, index) => {
        noIncident.push(
            data - incident[index]
        );
    })

    return noIncident;
}

export const WarningProvider = ({ children }) => {
    let todayTotalSet = generateTotalWarnings();
    let yesterdayTotalSet = generateTotalWarnings();
    
    let todaySDFunctionalSet = customSDFunctional(todayTotalSet);
    let yesterdaySDFunctionSet = customSDFunctional(yesterdayTotalSet);

    let todaySDIncidents = customSDIncidents(todayTotalSet);
    let yesterdaySDIncidents = customSDIncidents(yesterdayTotalSet);

    let todaySDIncidentTotal = totalSDIncidents(todaySDIncidents);
    let yesterdaySDIIncidentTotal = totalSDIncidents(yesterdaySDIncidents);

    let todayOvercrowded = customOverCrowdedArea(todayTotalSet);
    let yesterdayOvercrowded = customOverCrowdedArea(yesterdayTotalSet);

    let todayNoIncident = generateNoIncident(todayTotalSet, todaySDIncidents);
    let yesterdayNoIncident = generateNoIncident(yesterdayTotalSet, yesterdaySDIncidents);

    const dataset = {
        yesterday : {
            totalIncidents : {
                label : "Total Incidents",
                data : yesterdaySDIIncidentTotal,
                graph : true,
                tooltip : true
            },
            totalUsers : {
                label : "Total Users",
                data : yesterdayTotalSet,
                graph : true,
                tooltip : true
            },
            overCrowdedAreas : {
                label : "Overcrowded Areas",
                data : yesterdayOvercrowded,
                graph : true,
                tooltip : true
            },
            socialDistancingIncident : {
                label : "SD Incidents",
                data : yesterdaySDIncidents,
                graph : true,
                tooltip : true,
                unit : "hrs"
            },
            noIncident : {
                label : "No Incidents",
                data : yesterdayNoIncident,
                graph : true,
                tooltip : true,
                unit : "hrs"
            },
            sdFunctional : {
                label : "SD Functional",
                data : yesterdaySDFunctionSet,
                graph : true,
                tooltip : true,
                unit : "hrs"
            }
        },
        // 
        today : {
            timeline : null,
            totalUsers : {
                label : "Total Users",
                data : todayTotalSet,
                graph : true,
                tooltip : true
            },
            totalIncidents : {
                label : "Total Incidents",
                data : todaySDIncidentTotal,
                graph : true,
                tooltip : true
            },
            overCrowdedAreas : {
                label : "Overcrowded Areas",
                data : todayOvercrowded,
                graph : true,
                tooltip : true
            },
            socialDistancingIncident : {
                label : "SD Incidents",
                data : todaySDIncidents,
                graph : true,
                tooltip : true,
                unit : "hrs"
            },
            noIncident : {
                label : "No Incidents",
                data : todayNoIncident,
                graph : true,
                unit : "hrs"
            },
            sdFunctional : {
                label : "SD Functional",
                data : todaySDFunctionalSet,
                graph : true,
                tooltip : false,
                unit : "hrs"
            }
        }
    };
    return (
        <WarningData.Provider value={ dataset }>
            { children }
        </WarningData.Provider>
    )
}

export default TrendDataProvider;