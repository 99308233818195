import React, { useState } from 'react';
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faChartLine} from "@fortawesome/free-solid-svg-icons";

import classes from "./leftsidenav.module.scss";

const menuList = [
    {
        label: "Dashboard",
        route: "/home",
        icon: faHome
    },
    {
        label: "Analytics",
        route: "/analytics",
        icon: faChartLine
    }
];

const Menu = ({ label, icon, route, collapse }) => {

    const textContent = !collapse ? <div>{label}</div> : null;

    return (
        <NavLink to={route} activeClassName={classes.active}
            className={classes.nav_link}
        >
            <div className={classes.menu_icon}>
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className={classes.menu_title}>
                {textContent}
            </div>
        </NavLink>
    );
};

const MenuList = ({ collapse }) => {

    const menuRendering = menuList.map((content, index) => {
       return (
         <Menu key={index}
            icon={content.icon}
            route={content.route}
            collapse={collapse}
            label={content.label}
         />
       );
    });

    return (
        <div className={classes.menu_items}>
            <div className={classes.nav_links}>
                {menuRendering}
            </div>
        </div>
    )

};

const LeftSideNav = () => {
    const [collapse, setCollapse] = useState(true);

    const onMouseEnterHandler = () => {setCollapse(false)};
    const onMouseLeaveHandler = () => {setCollapse(true)};

    const collapsedStateClass = collapse ? classes.sidebar_collapsed : classes.sidebar_full;

    return (
        <div className={collapsedStateClass}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
        >
            <MenuList collapse={collapse}/>
        </div>
    )
};

export default LeftSideNav;