import React from "react";
import classes from "./SliderUI.module.scss";
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMatomo } from '@datapunt/matomo-tracker-react';
/**
 * Slider UI is the component in-charge of indicating the trend of the shopping mall.
 * The UI must have an icon on top representing the time of day. This icon includes
 * having a Sun at 7 AM and Moon at 7 PM.
 * 
 * The timeline starts at 7 AM and ends at 7 AM and so must contain one Moon Icon
 * in the middle and must have Sun icon at the start and end.
 * 
 * The icons go top, the timelines go in the middle and the time must be placed at 
 * the bottom
 */
const SliderUI = ({ children }) => {
    //Matomo Tracker
    const { trackEvent } = useMatomo()
    const handleOnClick = () => {
        // Track click on button
        trackEvent({ category: 'Button-Clicks', action: 'change-time', name: 'Timeline'})
    }
    return (
        <div className={classes.slider_wrapper}
        onClick={handleOnClick}>
            <div className={classes.slider_icon_wrapper}>
                <FontAwesomeIcon icon={faSun} size={"1x"} style={{color: "orange"}}/>
                <FontAwesomeIcon icon={faMoon} size={"1x"} style={{color: "#FFFFFF"}}/>
                <FontAwesomeIcon icon={faSun} size={"1x"} style={{color: "orange"}}/>
            </div>
            { children }
            <div className={classes.timelabel_wrapper}>
                <span>7am</span>
                <span>7pm</span>
                <span>7am</span>
            </div>
        </div>
    )
}


export default SliderUI;