import React, { useState } from "react";
import LineBarChart from "../Chart/LineBarChart";
import { Col } from 'react-bootstrap';

import InfoIcon from "../../images/info-dashboard.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useMatomo } from '@datapunt/matomo-tracker-react';
import classes from "./ChartCards.module.scss";

const LineBarCard = (props) => {
    const item = props.item;
    const [isClick, setIsClick] = useState(false)
    const clickHandler = () => {
        setIsClick(current => !current)
    }

    //Matomo Tracker
    const { trackEvent } = useMatomo()
    const handleOnClick = () => {
        // Track click on button
        trackEvent({ category: 'Button-Clicks', action: 'chart-info', name: 'LineBarChart'})
    }
    return (
        <Col className={classes.card_wrapper}>
            <div className={classes.card}>
                <div className={classes.info}
                     style={{ display: isClick ? 'none' : 'block' }}
                     onClick={() => {
                        clickHandler();
                        handleOnClick();
                        }}>
                    <img src={InfoIcon} alt="Information Icon" />
                </div>
                <div className={classes.name}>{item.label}</div>
                <div className={classes.chart}>
                    <LineBarChart lineData={item.lineData} barData={item.barData}
                        label_bar={item.label_bar} label_line={item.label_line}/>
                </div>
                <div style={{ transform: isClick ? 'scale(1)' : 'scale(0)' }}
                     className={classes.wrapperOverlay}>
                    <div className={classes.info}>
                        <FontAwesomeIcon icon={faTimes} size="lg" onClick={clickHandler} />
                    </div>
                    <div className={classes.description}>
                        {item.description}
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default LineBarCard;