// noinspection JSUnresolvedFunction

import React, {useContext} from "react";
import {
    ComposedChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    Label,
    Line,
    CartesianGrid
} from "recharts";
import { TimeManager} from "../../pages/NewDashBoard/components/Context/TimeLine";
import { timeStringFormat, getTimeLabel } from "../functions/backtrackTime";

// Import dayJS module to convert time between timezones
import dayjs from "dayjs";

let utc = require("dayjs/plugin/utc");
let timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Function to compare 2 Date X and Y. Return True if they are the same date, False otherwise.
 * @param {*} X : The date of the selected day.
 * @param {*} Y : The current date
 * @returns  Boolean - True if X and Y is the same date, False otherwise
 */
const compareDate = (X, Y) => {
    // Set hours, minutes, seconds and milliseconds to 0
    let selectedDate = X.hour(0).minute(0).second(0).millisecond(0);
    // selectedDate = selectedDate.hour(0).minute(0).second(0).millisecond(0);
    let currentDate = Y.hour(0).minute(0).second(0).millisecond(0);
    return (selectedDate.diff(currentDate) === 0);
}
/**
 * Function to check if a number has decimal or not.
 * @param {number} X Number to be checked
 * @returns boolean
 */
const checkDecimal = (X) => {
    return (X - Math.floor(X)) !== 0;
}

const CustomTooltip = (props) => {
    if (!props || !props.payload) {
        return null;
    }
    let payload;

    if (props.payload[0]) {
        payload = props.payload[0].value;
    }

    return (
        <div
            style={{
                background: "rgba(0, 0, 0, 0.85)",
                padding: "1em",
                textAlign: "center",
                borderRadius: "1em"
            }}
        >
            {props.label}
            <br/>
            {props.label_y ? props.label_y : "Value"}: {payload}
        </div>
    );
};

const LineChart = ({data}) => {
    // Timeline Manager Context
    // const timelineManager = useContext(TimeManager);

    const {value: currentTime} = useContext(TimeManager);
    // Get the date setting function
    // const {range} = timelineManager;

    // Variables to hold length of the current data and the data's content.
    let dataLength = 0;
    let dataArray = [];
    // Deconstruct the object into an array of values
    for (let key in data) {
        if (checkDecimal(data[key])) {
            dataArray.push(parseFloat(data[key].toFixed(3)));
        } else {
            dataArray.push(data[key]);
        }
        // Get length of the data array
        dataLength += 1;
    }

    const marginStyling = {
        top: 15,
        left: 20,
        right: 20,
        bottom: 20,
    };

    const labelStyling = {
        textAnchor: "middle",
        fill: "rgb(255, 255, 255)",
        fontWeight: "bold",
        fontSize: "medium",
    };

    if (!data || !dataLength) {
        return null;
    }
    const dataSet = [];

    // Calculate the number of intervals for the chart
    const timezone = localStorage.getItem('serverTimezone') ? localStorage.getItem('serverTimezone') : "Australia/Brisbane";

    let todayTime = dayjs(new Date()).tz();
    let selectedDate = todayTime.hour(7).minute(0).second(0).millisecond(0);
    let residual = dayjs(new Date()).tz(timezone).minute() > 30 ? 0.5 : 0;
    let currTime = dayjs(new Date()).tz(timezone).hour();
    let maxInterval = compareDate(selectedDate, dayjs(new Date()).tz(timezone)) ? (currTime - 7 + residual) * 2 : 48;
    const graphInterval = Math.round(maxInterval / 5);

    for (let index = 0; index <= maxInterval; index++) {
        // Get the format time for each index
        const adjustedTime = getTimeLabel(index, dayjs().tz(timezone), timezone);
        const timeString = timeStringFormat(adjustedTime, "dayjs");
        dataSet.push({
            time: index,
            timeString: timeString,
            value: dataArray[index],
        });
    }

    return (
        <div style={{width: "100%", height: "100%"}}>
            <ResponsiveContainer width={"100%"} height={"100%"}>
                <ComposedChart
                    margin={marginStyling}
                    data={dataSet}
                    width={700}
                    height={250}
                >
                    <defs>
                        <linearGradient id="LineGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            {/*Light Blue*/}
                            <stop offset="0%" stopColor="#217ee2"/>
                            {/*Dark Blue*/}
                            <stop offset="50%" stopColor="#0f3661"/>
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray={"3 3"} stroke={"rgba(76, 76, 76, 0.3)"}/>
                    <XAxis dataKey={"timeString"}
                           interval={graphInterval}
                           tick={true}
                           dy={15}
                    />

                    <YAxis type="number" tick={true} dataKey={"value"} domain={["dataMin", "dataMax"]}
                           interval={"preserveEnd"}
                    >
                        <Label
                            angle={-90}
                            style={labelStyling}
                            position={"insideLeft"}
                            dx={-10}
                        >
                        </Label>
                    </YAxis>
                    <Line
                        type={"monotone"}
                        isAnimationActive={false} dot={false} dataKey="value" stroke="url(#LineGradient)"
                        strokeWidth="6" radius={100}/>
                    <Tooltip content={<CustomTooltip time={currentTime} timezone={timezone}/>}/>

                </ComposedChart>
            </ResponsiveContainer>
        </div>

    );
};

export default LineChart;
