import React, {useEffect, useState} from 'react';
import generatePDF from "../../functions/generatePdf";
import {RangePicker} from "./RangePicker.jsx";
import DayTimeline from './DayTimeline';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import dayjs from 'dayjs';
// import calendarData from './calendarData.json';

// import generateCalendarData from "./calendarData";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport, faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import classes from './DaySlider.module.scss';
import Random from "../functions/Random";

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);

const DAYS_BEFORE = 180;

const SliderHeader = (props) => {
	const [visible, setVisible] = useState(false);
	const data = props.selectDays.filter(ele => ele.value <= props.maxDays);

	const onClickDays = (value) => {
		props.setDays(value);
		props.setUseCalendar(false);
	}

	const { trackPageView, trackEvent } = useMatomo()
	useEffect(() => {
    trackPageView()
      }, [trackPageView])
    // End of Update section.

	const selectedOption = [classes.days_selector_label, classes.selected_day];
	const onClickButton = (numberOfDays) => {
		onClickDays(numberOfDays);
		const lastDay = dayjs(new Date());
		const firstDay = lastDay.subtract(numberOfDays + 1, 'day');
		const newRange = [firstDay.toDate(), lastDay.toDate()];
		props.setSelectedDate(newRange);
	}

	    //Matomo Tracker
		const handleOnClick = () => {
			// Track click on button
			trackEvent({ category: 'Button-Clicks', action: 'change-period', name: 'Pre-Selected Periods Day Slider'})
			}
		//Matomo Tracker
		const handleOnClickPDF = () => {
			// Track click on button
			trackEvent({ category: 'Button-Clicks', action: 'print-PDF', name: 'ExportPDF'})
			}

	const listItem = data.map(ele =>
		<div key={ele.value}
			 className={(!props.useCalendar && ele.value === props.selected) ? selectedOption.join(" ") : classes.days_selector_label}
			 onClick={() => {
				onClickButton(ele.value);
				handleOnClick();
				}}>
			{ele.label}
		</div>
	)

	return (
		<div className={classes.header_container}>
			<div className={classes.selections}>
				{listItem}
				<div>
					<div
						className={(props.useCalendar) ?
							[classes.days_selector_label, classes.custom_range, classes.selected_day].join(" ") :
							[classes.days_selector_label, classes.custom_range].join(" ")
						}
						onClick={() => setVisible(!visible)}>
						<FontAwesomeIcon icon={faCalendarAlt}/>
						<span>Select Range</span>
					</div>

					<RangePicker visible={visible}
								 setVisible={setVisible}
								 selectedDate={props.selectedDate}
								 setDays={props.setDays}
								 setUseCalendar={props.setUseCalendar}
								 setSelectedDate={props.setSelectedDate}/>
				</div>

				<div className={classes.export_btn}
					 onClick={() => {
						generatePDF(props.useCalendar, props.calendarData, props.selectedDate, props.selected);
						handleOnClickPDF();
						}}>
					<FontAwesomeIcon icon={faFileExport}/>
					<span>Export to PDF</span>
				</div>
			</div>
		</div>
	)
};

const DaySlider = () => {
	const [days, setDays] = useState(14);
	const [useCalendar, setUseCalendar] = useState(false);
	const [calendarData, setCalendarData] = useState({});

	let dataLength = 0;
	let dataArray = [];

	const generateCalendarData = () => {
		const data = {};
		const today = dayjs(new Date());
		const firstDay = today.subtract(DAYS_BEFORE, 'day');

		for (let i = 1; i < DAYS_BEFORE; i++) {
			const date = firstDay.add(i, 'day').format('YYYY-MM-DD');
			data[date] = {
				"today_visitor": Random.valueBetween(9500, 21000),
				"today_pedestrian": 0,
				"today_shopper": 0,
				"created_at":  date + " 00:00:00"
			}
		}
		setCalendarData(data);
	}

	useEffect(() => {
		generateCalendarData();
	}, []);

	Object.keys(calendarData).forEach(key => {
		dataLength++;
		let dateLabel = key.slice(5);
		let s = key.slice(-2) + "-" + dateLabel.slice(0, 2);
		dataArray.push({
			date: s,
			fullDate: key,
			value: calendarData[key][`today_visitor`],
			shopper: calendarData[key][`today_shopper`],
			pedestrian: calendarData[key][`today_pedestrian`]
		})
	})

	const defaultDate = () => {
		const today = dayjs(new Date());
		const firstDay = today.subtract(15, 'day');
		return [firstDay.toDate(), today.toDate()];
	}
	const [selectedDate, setSelectedDate] = useState(defaultDate());

	const selectDays = [
		{label: "14 Days", value: 14},
		{label: "20 Days", value: 20},
		{label: "60 Days", value: 60}
	]

	return (
		<div className={classes.dayslider_card}>
			<SliderHeader
				selectDays={selectDays}
				selected={days}
				setDays={setDays}
				maxDays={dataLength}
				calendarData={dataArray}
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				useCalendar={useCalendar}
				setUseCalendar={setUseCalendar}
			/>
			<DayTimeline
				useCalendar={useCalendar}
				selectedDate={selectedDate}
				numberOfDays={days}
				calendarData={dataArray}
				dataLength={dataLength}/>
		</div>
	)

};

export default DaySlider;