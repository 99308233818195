import React, { useContext, useState } from 'react';
import classes from './slider.module.scss';
import { Timeline, TrendData, TimeManager, WarningData } from 
        '../Context/TimeLine';
import { useEffect } from 'react';
import { createRef } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import backtrackTime, { timeStringFormat } from "../../../../components/functions/backtrackTime.js";

import PlayPauseButton from "../PlayPauseButton/PlayPauseButton";
import CurrentButton from "../CurrentButton/CurrentButton";

import SliderUI from "../SliderUI/SliderUI";


const GAP = 0.25;

/** TODO: Put the components into separate folders
 * Currently having every component in one-place to easily edit
 * Will put them into components folder later
 */

const findMean = dataSet => {
    let total = 0;
    let dataSize = dataSet.length;
    dataSet.forEach(data => {
        total+= data;
    });
    return (
        total / dataSize
    )
}

const TimelineCanvas = ({timeline, data, mean, currentTime}) => {
    // Contains around 48 bars with each bar representing 30 minutes
    const { playTime } = timeline;
    const canvas = createRef();
    const context = useRef(null);
    const canvasDimension = useRef({
        width : 0,
        height : 0
    });

    const drawRectangle = useCallback(() => {
        // Draws little rectangles for each time period
        let contextObject = context.current;
        let { width : maxX, height : maxY } = canvasDimension.current;
        let totalSet = data.length;
        let binWidth = (1 - GAP) * maxX / totalSet;
        let binGap = GAP * maxX / totalSet;
        // Clear the rectangle
        contextObject.clearRect(0, 0, maxX, maxY);
        // Begin path
        contextObject.beginPath();
        // Draw the rectangles here
        data.forEach((data, index) => {
            if (index <= currentTime) {
                // If index <= currentPeriod then make it dark
                if (index <= playTime) {
                    contextObject.fillStyle = "#ffa500";
                } else {
                    // Else make it light
                    contextObject.fillStyle = "#1e7fe6";
                }

                let width = binWidth;
                let height = (0.5 * maxY * data) / mean;
                let x = (width + binGap) * index;
                let y = maxY - height;
                contextObject.fillRect(x, y, width, height);
            }
            
        });
        contextObject.closePath();
    }, [playTime, data, mean, canvasDimension, context, currentTime]);

    const setCanvas = useCallback(node => {
        if (node) {
            canvas.current = node;
            context.current = node.getContext('2d');
        }
    }, [canvas]);

    const resizeCanvas = useCallback(() => {
        // Here resize the canvas and call the retangle function
        let canvasDOM = canvas.current;
        let { width, height } = window.getComputedStyle(canvasDOM);
        [ width, height ] = [parseFloat(width) , parseFloat(height)];
        // Set the dimension on the react object
        canvasDimension.current = {
            width, height
        };
        // Set width and height of canvas dom
        canvasDOM.setAttribute("width", width);
        canvasDOM.setAttribute("height", height);
        // Draw the image
        drawRectangle();
    }, [canvas, drawRectangle, canvasDimension]);

    useEffect(() => {
        resizeCanvas();
        window.addEventListener("resize", resizeCanvas);
        return () => {
            window.removeEventListener("resize", resizeCanvas);
        }
    }, [resizeCanvas]);

    return (
        <canvas ref={setCanvas} 
                    className={classes.wave}></canvas>
    );
}

// Using data key to state which data must be used for visualisation
const TimelineWave = ( { timelineProvider, trendData, dataKey, currentTime, classes } ) => {
    const toolTip = createRef();
    const timewaveWrapper = createRef();
    const { setPlayTime } = timelineProvider;
    const { today } = trendData;
    const information = [];
    const { data : visualisationData } = today[dataKey];
    const meanData = findMean(visualisationData);

    const [mousePosition, setMousePosition] = useState({
        x : null, y : null
    });
    const [whichTime, setIndex] = useState(0);
    const [toolTipState, setToolTipState] = useState({
        visible : false,
        x : null,
        y : null
    })


    for (let key in today) {
        let selected = today[key];
        if (selected.tooltip) {
            information.push(today[key]);
        }
    }

    const setTimeWaveWrapper = useCallback(node => {
        timewaveWrapper.current = node;
    }, [timewaveWrapper]);

    const setToolTip = useCallback(node => {
        toolTip.current = node;
    }, [toolTip]);

    const onMouseOver = useCallback(event => {
        setMousePosition({
            x : event.clientX,
            y : event.clientY
        })
    }, []);

    const onMouseLeave = useCallback(() => {
        setToolTipState(state => {
            state.visible = false;
            return state;
        });
    }, []);

    const findIndex = useCallback(x => {
        // Get the total data set
        let totalSet = visualisationData.length;
        // Get the width of the wrapping element
        let widthString = 
        getComputedStyle(timewaveWrapper.current)
                .getPropertyValue("width");
        let width = parseFloat(widthString);
        // Consider the GAP constant
        let binWidth = (1 - GAP) * width / totalSet;
        let binGap = GAP * width / totalSet;
        // Find the index making sure it doesn't go outside the range
        // [0, totalSet - 1]
        let index = 
            Math.min(
                Math.max(Math.floor(x / (binWidth + binGap)), 0),
                totalSet - 1
            );

        return index;
    }, [visualisationData, timewaveWrapper]);

    const relativeMousePosition = useCallback((newX, newY) => {
        let boundingRect = timewaveWrapper.current
                    .getBoundingClientRect();
        // calculate which index it is moused over
        let x = newX - Math.floor(boundingRect.left);
        let y = newY - Math.floor(boundingRect.top);
        
        let maxX = boundingRect.width;
        let maxY = boundingRect.height;

        return {
            x,
            y,
            maxX,
            maxY
        }
    }, [timewaveWrapper]);

    const onMouseClick = useCallback(() => {
        // Here get the state index on just set it to context
        let { x : newX, y : newY } = mousePosition;
        if (newX && newY) {
            let { x } = relativeMousePosition(newX, newY);
            let index = findIndex(x);
            if (index >= 0) {
                let adjustedIndex = Math.min(currentTime, index);
                setPlayTime(adjustedIndex);
            }
        }
    }, [setPlayTime, mousePosition, relativeMousePosition, findIndex, currentTime]);

    useEffect(() => {
        let { x : newX, y : newY } = mousePosition;
        if (newX && newY) {
            // Adjust x and y position
            let { x, y, maxY } = relativeMousePosition(newX, newY);
            
            setToolTipState(state => {
                // Find the value
                let index = findIndex(x);
                if (index >= 0 && index <= currentTime) {
                    let value = visualisationData[index];
                    let height = (0.5 * maxY * value) / meanData;
                    let barY = maxY - height; 
                    // If y >= barY then set the visible to be true
                    // and index
                    if (y >= barY) {
                        state.visible = true;
                        state.transform = {
                            x : x,
                            y : y
                        }
                        setIndex(index);
                    } else {
                        state.visible = false;
                    }
                } else {
                    state.visible = false;
                }
                return state;
            });
        }
    }, [toolTip, mousePosition, visualisationData, relativeMousePosition,
             findIndex, meanData, currentTime]);

    useEffect(() => {
        let opacity = 1;
        let toolTipDOM = toolTip.current;
        let { visible, transform } = toolTipState;
        if (!visible) {
            opacity = 0;
        } else {
            toolTipDOM.style.setProperty(
                `transform`,
                `matrix(1, 0, 0, 1, ${transform.x + 5}, ${transform.y - 2})`
            );
        }
        toolTipDOM.style.setProperty("opacity", opacity, "important");
    }, [toolTipState, toolTip]);

    // Rendered content
    let mouseOveredTime = backtrackTime(whichTime, currentTime, new Date());
    let timeFormat = timeStringFormat(mouseOveredTime);

    return (
        <div onMouseDown={onMouseClick}
            onMouseMove={onMouseOver} onMouseLeave={onMouseLeave}
            className={classes.timewave_wrapper}
            ref={setTimeWaveWrapper}>
            <div className={classes.tooltip_wrapper} ref={setToolTip}>
                <div className={classes.tooltip_content_table}>
                    <div className={[classes.tooltip_content_row, classes.tooltip_content_heading].join(" ")}>
                        { timeFormat }
                    </div>
                    {
                        information.map(( { data, label }, index ) => {
                            return (
                                <div key={index} className={classes.tooltip_content_row}>
                                    {`${label} ${data[whichTime]}`}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <TimelineCanvas timeline={timelineProvider} currentTime={currentTime}
                mean={meanData}
                data={visualisationData} />
        </div>
    );
}

/**
 * This is responsible for selecting context for both the timeline and trend data
 * Customised individually depending on use case. The time manager is common to both.
 */
export const SliderDataProvider = ({ fullscreen }) => {
    const timeline = useContext(Timeline);
    const trendData = useContext(TrendData);
    const timeManager = useContext(TimeManager);
    
    return (
        <Slider fullscreen={fullscreen} timeline={timeline} trendData={trendData} 
            timeManager={timeManager} dataKey={"currentUsers"} />
    )
}

/**
 * 
 * Slider for the warnings page providing data about total users, incidents,
 * overcrowded areas and Social distancing information
 */
export const SliderWarningProvider = () => {
    const timeline = useContext(Timeline);
    const timeManager = useContext(TimeManager);
    const warningData = useContext(WarningData);
    
    return (
        <Slider timeline={timeline} trendData={warningData}
            timeManager={timeManager} dataKey={"totalUsers"} />
    );
}


export const Slider = 
    ({timeline, trendData, timeManager, dataKey, fullscreen}) => {
    let { value : currentTime } = timeManager;
    let innerBoxClassList = [classes.inner_box];

    if (fullscreen) {
        innerBoxClassList.push(
            classes.full_screen
        );
    };

    return (
        <div className={innerBoxClassList.join(" ")}>
            <div className={classes.layer}>
                <div className={[classes.component_horizontal, classes.timeline_wrapper].join(' ')}>
                    <PlayPauseButton />
                    <SliderUI>
                        <TimelineWave
                            classes={classes}
                            dataKey={dataKey} 
                            currentTime={currentTime} 
                            timelineProvider={timeline} 
                            trendData={trendData} />
                    </SliderUI>
                    <CurrentButton></CurrentButton>
                </div>
            </div>
        </div>
    )
};


export default SliderDataProvider;