
const getHistoricalData = (todaySet, yesterDaySet, playTime) => {
    let lastTen = [];
    let max = -Infinity;
    let min = Infinity;
    let retrospectTime = playTime - 9;

    for (let index = retrospectTime; index < playTime; index++) {
        let { data : dataSet } = todaySet;
        let localIndex = index;
        if (localIndex < 0) {
            dataSet = yesterDaySet.data;
            localIndex = dataSet.length + index - 1;
        }

        let value = dataSet[localIndex];

        max = Math.max(max, value);
        min = Math.min(min, value);

        lastTen.push({value});
    }

    return {
        dataSet: lastTen,
        max: max,
        min: min
    };
}

export default getHistoricalData;