export const intervalSelectStyle = {
    container : (provided) => ({
      ...provided,
      width : 80,
      maxWidth : "100%",
      margin : "auto"
    })
}


const selectStyles = {
    input : () => ({
        color : "#eee"
    }),
    container : (provided) => ({
        ...provided,
        width : 170,
        maxWidth : "100%",
        margin : "auto"
    }),
    singleValue : (provided) => ({
        ...provided,
        color : "#eee"
    }),
    indicatorSeparator : () => ({
        display : "none"
    }),
    menu : (provided) => ({
        ...provided,
        marginTop : 0
    }),
    menuList : (provided) => ({
        ...provided,
        paddingTop : 0
    }),
    option : (provided, state) => {
        let color = "#999";
        if (state.isSelected) {
        color = "#00afef";
        }
        return {
            ...provided,
            backgroundColor : "white",
            color : color,
            fontSize : "1.1em"
        }
    },
    control : (provided) => ({
        ...provided,
        backgroundColor : "#1a1a1a",
        color : "#eee",
        borderWidth : 0,
        borderRadius : 0
    })
}
export default selectStyles;