import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import {Col, Row} from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";


import classes from './home.module.scss';
import SliderAnalytics from "../NewDashBoard/components/SliderAnalytics/SliderAnalytics";
import DaySlider from "../../components/DaySlider";
import {Cards} from "../NewDashBoard/components/Cards/Cards";
import {GeoHeatMapContext} from "../NewDashBoard/components/Context/GeoHeatMap";
import GeoHeatMap from "../NewDashBoard/components/HeatMap/GeoHeatMap";
import { useMatomo } from '@datapunt/matomo-tracker-react'


const Home = () => {

const { trackPageView } = useMatomo()
useEffect(() => {
    trackPageView()
      }, [trackPageView])
    // End of Update section.
    return (
        <div className={classes.scrollable}>
            <Helmet>
                <title>SPACE Dashboard - Home</title>
            </Helmet>
            <Row>
                {/*Render Timeline*/}
                <Row className={classes.today_data_wrapper}>
                    <Col className={classes.today_data}>
                        <Row>
                            <SliderAnalytics />
                        </Row>
                    </Col>
                </Row>
                {/*Render DaySlider*/}
                <Col className={classes.card_wrapper}>
                    <DaySlider/>
                </Col>
                {/*Render Shoppers Pedestrians Card*/}
                <Row className={classes.statistics_wrapper}>
                    <Cards/>
                </Row>
                {/*Render Heatmap*/}
                <Row className={classes.statistics_wrapper} styles={"padding-top: 1rem"}>
                    <GeoHeatMapContext>
                        <GeoHeatMap/>
                        <GeoHeatMap historic/>
                    </GeoHeatMapContext>
                </Row>
            </Row>

        </div>
    )
};

export default Home;