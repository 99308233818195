import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { Provider } from './Context';
import Dashboard from "./pages/Dashboard";

const App = () => {
  return (
    <ToastProvider placement="top-center">
      <Provider>
        <Switch>
            {/*<Route path="/" component={NewDashBoard} />*/}
            <Route path='/' component={Dashboard}/>
        </Switch>
      </Provider>
    </ToastProvider>
  );
};

export default App;
