/**
 * Sorts a given polygon set by its area percentage
 * @param {Object} a set containing percentage
 * @param {Object} b set contaning percetange
 */
const polygonComparator = (a, b) => {
    if (a.percentage < b.percentage) {
        return -1;
    } else if (a.percentage > b.percentage) {
        return 1;
    }

    return 0;
};

export default polygonComparator;