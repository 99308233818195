
import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-regular-svg-icons';

import classes from "./PlayPauseButton.module.scss";

import backtrackTime, { Month, timeStringFormat } from "../../../../components/functions/backtrackTime.js";
import { Timeline, TimeManager } from "../Context/TimeLine";

const PlayPauseButton = () => {
    const timeline = useContext(Timeline);
    const { value : currentTime } = useContext(TimeManager);
    const size = "2x";
    const { playing, togglePlaying, playTime } = timeline; 
    let adjustedPlayTime =  backtrackTime(playTime, currentTime, new Date());
    // For Day and Month
    const todayString = `${adjustedPlayTime.getDate()} ${Month[adjustedPlayTime.getMonth()]}`;
    // Conditional Rendering
    let fontIcon;
    if (playing) {
        fontIcon = faPauseCircle;
    } else {
        fontIcon = faPlayCircle;
    }

    let timeString = timeStringFormat(adjustedPlayTime);
    return (
        <div className={classes.play_pause_btn}>
            <span className={classes.day_label}>
                { todayString }
            </span>
            <FontAwesomeIcon className={classes.play_btn} onClick={ togglePlaying } icon={fontIcon} 
                    size={size}></FontAwesomeIcon>
            <span className={classes.time_label}>
                { timeString }
            </span>
        </div>
    )
};

export default PlayPauseButton;