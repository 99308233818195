import React, { useContext } from 'react';
import NewStatistics from './components/NewStatistics/NewStatistics.jsx';
import { Timeline, TrendData } from '../Context/TimeLine.js';
import getDelta from "../../../../functions/getDelta";
import getHistoricalData from "../../../../functions/getHistoricalData";

// TrendData contains data
// TimeLine contains the time information and sets the current data
export const Cards = () => {
    const { playTime } = useContext(Timeline);
    const { today, yesterday } = useContext(TrendData);

    let delta = getDelta(today, yesterday, playTime);

    const cards = [];

    Object.keys(today).filter(e => {
        return today[e].viewable;
    }).forEach(x => {
        let todaySet = today[x];
        let { label, graph, data : todayData, unit, inHome, cardTooltip } = todaySet;
        let yesterdaySet = yesterday[x];
        let historicalSet = 
                getHistoricalData(todaySet, yesterdaySet, playTime);
        let subTitle = "Compared to Yesterday";
        let display = todayData[playTime];

        if (inHome) {
            cards.push(
                <NewStatistics unit={unit} graph={graph} subTitle={subTitle} 
                    info={display} delta={delta[x]} 
                    historical={historicalSet} key={x} 
                    tooltip={cardTooltip}>
                    {label}
                </NewStatistics>
            );
        }
    });

    return cards;
}
