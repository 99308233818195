import React, { forwardRef, useRef, useLayoutEffect, useCallback, useState } from "react";
import classes from "./statistics.module.scss";
import { Col } from "react-bootstrap";
import { AreaChart, XAxis, YAxis, Area, ResponsiveContainer } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import infoIconSVG from "../../../../../../images/info-dashboard.svg";

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const CardTooltip = ({ children, message }) => {
    const [width, setWidth] = useState(1000);
    const tooltipMessageWrapper = useRef(null);

    const setTooltipWrapper = useCallback(node => {
        tooltipMessageWrapper.current = node;
    }, [])

    useLayoutEffect(() => {
        const boundingRectangle = tooltipMessageWrapper.current.getBoundingClientRect();
        const { width } = boundingRectangle;
        
       setWidth(width + 22);
        
    }, []);

    return (
        <div className={classes.tooltip}>
            <div className={classes.tooltip_content}>
                { children }
            </div>
            <div className={classes.tooltip_message} style={{
                width : `${width}px`
            }}>
                <span ref={setTooltipWrapper}>
                    { message }
                </span>
            </div>
        </div>
    )
}

const CardIcon = ( { value }) => {
    return (
        <div className={classes.delta_pct_wrapper}>
            <div className={[classes.delta_pct, classes.icon].join(" ")}>
                <FontAwesomeIcon icon={value} size={"1x"} />
            </div>
        </div>
    )
}

const DeltaPercentage = ( { value } ) => {
    let indicatorClass = classes.neutral;
    if (value > 0) {
        // Positive
        indicatorClass = classes.positive;
    } else if (value < 0) {
        // Negative
        indicatorClass = classes.negative;
    }

    return (
        <div className={classes.delta_pct_wrapper}>
            <div className={[classes.delta_pct, indicatorClass].join(" ")}>
                { value.toPrecision(2) }
            </div>
        </div>
    )
}

const TrendData = ( { children, value, unit } ) => {
    return (
        <div className={classes.trend_wrapper}>
            <div className={classes.current_trend}>
                {`${numberWithCommas(value)}`}
                <span> {unit ? unit : ''}</span>
            </div>
            <div className={classes.label}>
                { children }
            </div>
        </div>
    );
}

const NewStatistics = forwardRef(( { index, graph, information, icon, children, style,
            onMouseDown, onMouseUp, onMouseMove, historical, delta, info, unit, tooltip }, ref) => {
    // These are optional UIs. Using conditional rendering
    let graphArea,
        deltaPercentage;
    // let [width, setWidth] = useState(100);
    
    const cardParentWrapper = useRef(null);

    if (delta !== void 0) {
        deltaPercentage = <DeltaPercentage value={delta}/>;
    } else if (information !== void 0) {
        deltaPercentage = <CardIcon value={icon}/>;
    }

    // const changeWidth = useCallback(() => {
        // const element = cardParentWrapper.current;
        // const computedStyle = getComputedStyle(element);
        // const widthStyle = parseFloat(computedStyle.width);

        // setWidth(widthStyle);
    // }, []);

    const setParentWrapper = useCallback(node => {
        cardParentWrapper.current = node;
    }, []);

    // useEffect(() => {
    //     changeWidth();
    //     const intervalId = setInterval(changeWidth, 250);
    //     return () => {
    //         clearInterval(intervalId);
    //     }
    // }, [changeWidth])

    if (graph) {
        graphArea = (
            <div className={classes.card_graph}>
                <ResponsiveContainer height={70} width="100%">
                    <AreaChart height={70}
                        data={historical.dataSet} margin={{
                            top : 0,
                            right : 0,
                            left : 0,
                            bottom : 0
                    }}>
                        <defs>
                            <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="0%" stopColor="#02659d" />
                                <stop offset="18%" stopColor="#3a93d5" />
                                <stop offset="38%" stopColor="#73afe9"  />
                                <stop offset="100%" stopColor="#c7f1fc" />
                            </linearGradient>
                        </defs>
                        <XAxis hide dataKey={"value"}/>
                        <YAxis hide />
                        <Area isAnimationActive={false} type="natural"
                            dataKey={"value"} stroke={"none"}
                            fill={"url(#colorGradient)"} />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        )
    }
    return (
        <Col style={style} ref={ref}
                className={[classes.card_wrapper].join(" ")}
                onMouseDown={onMouseDown} onMouseUp={onMouseUp}
                onMouseLeave={onMouseUp}
                onMouseMove={onMouseMove} data-index={index}
                onTouchStart={onMouseDown} onTouchEnd={onMouseUp}
                onTouchCancel={onMouseUp}
                onTouchMove={onMouseMove}>
            <div ref={setParentWrapper} className={[classes.card, "card_print"].join(" ")}>
                <div className={classes.information}>
                    <CardTooltip message={
                        <span>
                            { tooltip }
                        </span>
                    }>
                        <img src={infoIconSVG} alt={"Information Icon"} />
                    </CardTooltip>
                    { deltaPercentage }
                    <TrendData value={info} unit={unit}>
                        { children }
                    </TrendData>
                </div>
                { graphArea }
            </div>
        </Col>
    );
});

export default NewStatistics;
