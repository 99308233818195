import React, { Component } from 'react';
import jwtDecode from 'jwt-decode';
export const Context = React.createContext();

export class Provider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      setUserInfo: (token) => {
        const metadata = jwtDecode(token);
        this.setState({
          userInfo: metadata.scope,
        })
      },
  
      getUserType: () => {
        const token = localStorage.getItem('client-token');
        if (!token) {
          return null;
        }
        const metadata = jwtDecode(token);
        return metadata.scope.type;
      },
  
      getToken: () => {
        return localStorage.getItem('client-token');
      },
  
      setUser: (index, id) => {
        localStorage.setItem('user', [index,id]);
      },
  
      getUser: () => {
        return localStorage.getItem('user');
      },
  
      signOut: () => {
        localStorage.removeItem('client-token');
        localStorage.removeItem('user');
        return true;
      }
    }
  }

  render() {

    return(
      <Context.Provider value={{ state: this.state }}>
        {this.props.children}
      </Context.Provider>
    );
  }
};
