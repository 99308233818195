import React, { useContext, useEffect, 
    useState, useRef, createContext } from "react";
import { Map, TileLayer } from "react-leaflet";
import classes from "./heatmap.module.scss";
import Select from "react-select";
import selectStyles from "./selectStyle";
import { Col } from "react-bootstrap";
import { faUsers, faExpand, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimeManager, Timeline } from "../Context/TimeLine";
import { GeoHeatMapTimeline } from "../Context/GeoHeatMap.js";
import HeatmapOverlay from "leaflet-heatmap";
import { useCallback } from "react";
import { Month, timeStringFormat } from "../../../../components/functions/backtrackTime.js";
import Slider from "../Slider/Slider";
import { useMatomo } from '@datapunt/matomo-tracker-react';

const configuration = {
    radius : 3e-4,
    maxOpacity : .9,
    scaleRadius : true,
    useLocalExtrema : false,
    latField : "x",
    lngField : "y",
    valueField : "value"
};

const Setting = createContext();


const MapHeader = () => {
    const { getUsers, getTimestamp, 
            getSelectOptions, setSelectOptions, getMap } = useContext(Setting);
    return (
        <div className={classes.heatmap_lvl_container}>
            <div className={classes.heatmap_lvl_select}>
                <Select onChange={setSelectOptions}
                    styles={selectStyles}  defaultValue={getMap()}
                    options={getSelectOptions()} />
            </div>
            <div className={classes.heatmap_title}>
                { getTimestamp() }
            </div>
            <div className={classes.heatmap_users}>
                <div className={classes.heatmap_icon}>
                    <FontAwesomeIcon icon={faUsers} style={{fontSize : "1.5em"}}></FontAwesomeIcon>
                </div>
                <div className={classes.heatmap_users_label}>
                    { getUsers() }
                </div>
            </div>
        </div>
    )
}

const NormalScreenMap = ({ fullscreen }) => {
    let heatmapStyle = [classes.heatmap_innerbox];
    const heatMap = useRef(null);
    const leafletMap = useRef(null);
    const { getCenter, setCenter, getDataset, setUsers, setZoom, getZoom, toggleFullscreen, getFullscreen } = 
                useContext(Setting);
    const [loaded, setLoaded] = useState(false);

    const setMapElement = useCallback(element => {
        if (!element) {
            return ;
        }
        let heatmapLayer = new HeatmapOverlay(configuration);

        element.leafletElement.addLayer(heatmapLayer);
        heatMap.current = heatmapLayer;
        leafletMap.current = element;
        setLoaded(true);
    }, []);

    const setCenterPosition = useCallback(() => {
        const { center } = leafletMap.current.viewport;
        setCenter(center, leafletMap.current.leafletElement);
    }, [setCenter]);

    const setZoomHandler = useCallback(event => {
        // let zoomLevel = (event.target.getZoom());
        const { zoom } = leafletMap.current.viewport;
        setZoom(zoom, leafletMap.current.leafletElement);
    }, [setZoom])
    
    const position = getCenter();
    const zoom = getZoom();
    
    //Matomo Tracker
    const { trackEvent } = useMatomo()
    const handleOnClick = () => {
        // Track click on button
        trackEvent({ category: 'Button-Clicks', action: 'heatmap-full', name: 'Full Screen Map'})
    }

    /**
     * According to this github issue https://github.com/neontribe/gbptm/issues/206
     * the bug happens when the leaflet tries to access the layer that has been already removed.
     * But it is also mentioned that react-leaflet should handle this, and avoid the bug.
     * This meant the design of the component is not really suitable for the react-leaflet
     * management of layers. Hence doing a conditional check to ensure the layer exists 
     * before getting boundary information for user detection.
     */
    useEffect(() => {
        let instance = heatMap.current;
        let model = getDataset();
        if (instance && loaded) {
            instance.setData(model);
            let leafletElement = leafletMap.current.leafletElement;
            let hasHeatmapLayer = leafletElement.hasLayer(instance);
            if (hasHeatmapLayer) {
                setUsers(leafletElement);
            }
        }
    }, [getDataset, heatMap, loaded, setUsers])

    if (!position || !position.length) {
        return null;
    }

    // Special styling
    if (fullscreen) {
        heatmapStyle.push(classes.fullscreen_innerbox);
    }

    let fullScreenStatusIcon = faExpand;

    // Change the icon based on what is the state
    if (getFullscreen()) {
        fullScreenStatusIcon = faTimes;
    }

    return (
        <Col className={heatmapStyle.join(" ")}>
            <MapHeader></MapHeader>
            <div className={classes.fullscreen_option}>
                <button onClick={() => {
                    toggleFullscreen();
                    handleOnClick();
                }} className={classes.fullscreen_btn}>
                    <FontAwesomeIcon icon={fullScreenStatusIcon} size={"1x"}></FontAwesomeIcon>
                </button>
            </div>
            <Map center={ position } zoom={ zoom } ref={setMapElement} 
                    className={classes.leaflet_container} minZoom={16} onmoveend={setCenterPosition}
                    onzoomend={setZoomHandler}
                >
                <h1>Loading...</h1>

                <TileLayer
                    reuseTiles={true}
                    
                    url="https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}.png?key=GTEYPLuX9c1M3y4tP9wX"
                    attribution={
                        "&copy; <a href=https://osm.org/copyright>" +
                        "OpenStreetMap</a> contributors"
                    }
                />
            </Map>
        </Col>
    )
}

const FullScreenMap = () => {
    return (
        <div className={classes.fullscreen_module_wrapper}>
            <div className={classes.fullscreen_slider_wrapper}>
                <Slider fullscreen={true}></Slider>
            </div>
            <div className={classes.fullscreen_heatmap}>
                <NormalScreenMap fullscreen></NormalScreenMap>
            </div>
        </div>
    );
}

const SettingContext = ({ children, historic }) => {
    const [map, setMap] = useState(0);
    const [zoom, setZoomLevel] = useState(17);
    const [users, setWindowedUsers] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);
    const [centerPosition, setCenterPosition] = useState([]);
    // Importing all the context api databases
    const { findHeatMapSet } = useContext(GeoHeatMapTimeline);
    const { value : time } = useContext(TimeManager);
    const { playTime } = useContext(Timeline);
    // Loading data
    let currentTime = historic ? playTime : time;

    const calculateUsers = useCallback(leafletElement => {
        if (!leafletElement) {
            return ;
        }
        let { indexing } = findHeatMapSet(currentTime)[map];
        let { _southWest, _northEast } = leafletElement.getBounds();
            let boundary = {
                x1 : _southWest.lat,
                x2 : _northEast.lat,
                y2 : _northEast.lng,
                y1 : _southWest.lng
            };
        let { count } = indexing.rangeQuery(boundary);
        setWindowedUsers(count);
    }, [findHeatMapSet, currentTime, map]);

    const state = {
        /**
         * Given a leaflet element calculates the total users in that
         * boundary space
         */
        getUsers : useCallback(() => {
            return users;
        }, [users]),
        setUsers : calculateUsers,
        /**
         * From the provided data of GeoHeatMap gets the time format information
         * for children elements to display
         */
        getTimestamp : useCallback(() => {
            if (!historic) {
                return ;
            }
            let timestamp = new Date(playTime);
            let timeString = timeStringFormat(timestamp);
            return `At ${timestamp.getDate()} ${Month[timestamp.getMonth()]} - ${timeString}`;
        }, [playTime, historic]),
        /**
         * Sets the center position of the map to be displayed even during transition
         * from the fullscreen and normal mode and vice versa
         */
        setCenter : useCallback((nextCenterPosition, leafletElement) => {
            setCenterPosition(nextCenterPosition);
            calculateUsers(leafletElement);
        }, [calculateUsers]),
        /**
         * Gets the center position already set
         */
        getCenter : useCallback(() => {
            return centerPosition;
        }, [centerPosition]),
        /**
         * Sets the zoom level of the map
         */
        setZoom : useCallback((nextZoomLevel, leafletElement) => {
            setZoomLevel(nextZoomLevel);
            calculateUsers(leafletElement);
        }, [calculateUsers]),
        /**
         * Gets the current zoom level of the map
         */
        getZoom : () => {
            return zoom;
        },
        /**
         * Sets the level selection for the entire component
         */
        setSelectOptions : ({ value }) => {
            setMap(value);
        },
        /**
         * Gets the selection option
         */
        getSelectOptions : useCallback(() => {
            let heatMapDataset = findHeatMapSet(currentTime);
            let selectOptions = [];

            heatMapDataset.forEach((element, index) => {
                selectOptions.push({
                    label : element.name,
                    value : index
                });
            });

            return selectOptions;

        }, [currentTime, findHeatMapSet]),
        /**
         * Toggles between fullscreen and normal mode
         */
        toggleFullscreen : useCallback(() => {
            setFullscreen(!fullscreen);
        }, [fullscreen]),
        /**
         * Gets the fullscreen status of the map
         */
        getFullscreen : () => {
            return fullscreen;
        },
        /**
         * Gets the dataset from 
         */
        getDataset : useCallback(() => {
            let heatMapDataSet = findHeatMapSet(currentTime);
            if (!heatMapDataSet.length) {
                return {
                    data : [],
                    max  : 0
                };
            }

            let { data : model, max } = heatMapDataSet[map];
            return {
                data : model,
                max : max
            }
        }, [map, currentTime, findHeatMapSet]),
        /**
         * Gets the present map information
         */
        getMap : useCallback(() => {
            let heatMapDataset = findHeatMapSet(currentTime);
            let selectOptions = [];

            heatMapDataset.forEach((element, index) => {
                selectOptions.push({
                    label : element.name,
                    value : index
                });
            });
            return selectOptions[map];
        }, [map, currentTime, findHeatMapSet])
    }

    useEffect(() => {
        let heatMapDataSet = findHeatMapSet(currentTime);
        let { map : location } = heatMapDataSet[map];
        setCenterPosition(location);
    }, [map, currentTime, findHeatMapSet]);

    return (
        <Setting.Provider value={ state }>
            { children }
        </Setting.Provider>
    )
};

const GeoController = () => {
    const { getFullscreen } = useContext(Setting);
    let fullscreen = getFullscreen();

    let childrenElement = <NormalScreenMap></NormalScreenMap>;
    if (fullscreen) {
        childrenElement = <FullScreenMap></FullScreenMap>;
    }

    return childrenElement;
}

const GeoHeatMap = ({ ...props }) => {
    return (
        <SettingContext {...props}>
            <GeoController></GeoController>
        </SettingContext>
    );
}

export default GeoHeatMap;