import React, { useCallback, useContext } from "react";
import { BarChart, ResponsiveContainer, Tooltip, XAxis, Bar, Rectangle } from "recharts";
import backtrackTime, { timeStringFormat } from "../../../../components/functions/backtrackTime";
import { Timeline, TimeManager, TrendData } from "../Context/TimeLine";
import CurrentButton from "../CurrentButton/CurrentButton";
import PlayPauseButton from "../PlayPauseButton/PlayPauseButton";
import SliderUI from "../SliderUI/SliderUI";
import classes from "./SliderAnalytics.module.scss";

const CustomTooltip = (props) => {
    if (!props || !props.payload) {
        return null;
    };
    const adjustedTime = backtrackTime(props.label, props.time, new Date());
    const timeString = timeStringFormat(adjustedTime);
    const payLoadRender = props.payload.map((e, i) => {
        return <div className={classes.tooltip_content_wrapper} key={i}>
            <div className={classes.rectangle} 
                style={{backgroundColor : e.fill}}></div>
            <div className={classes.text_content}>
                { e.dataKey } - { e.value } 
            </div>
        </div>
    });


    return <div className={classes.tooltip}>
        { timeString }
        { payLoadRender }
    </div>
};

const CustomBar = (props) => {
    let opacity = 1;
    if (props.playTime < props.payload.time) {
        opacity = 0.2;
    };

    return <Rectangle {...props} fillOpacity={opacity}></Rectangle>
};

const PedastrianShopper = () => {
    const marginStyling = {
        bottom : 0,
        left : 10,
        right : 10,
        top : 0
    };
    const { setPlayTime, playTime } = useContext(Timeline);
    const { value : currentTime } = useContext(TimeManager);
    const { today } = useContext(TrendData);
    const { shoppersPercentage : { data : shopperPercentage },
            currentUsers : { data : currentVisitors } } = today;
    const randomSet = [];
    
    const changeTime = useCallback(props => {
        // It has tendency to be null
        if (props) {
            const { activeTooltipIndex : index } = props;
            setPlayTime(index);
        }
    }, [setPlayTime]);


    for (let index = 0; index <= currentTime; index++) {
        randomSet.push(
            {
                time: index,
                Shopper: Math.round(currentVisitors[index] * shopperPercentage[index]),
                Pedestrian: Math.round((1 - shopperPercentage[index]) * currentVisitors[index])
            }
        );
    }

    return (
        <ResponsiveContainer width={"95%"} height={75}>
            <BarChart height={50}
                onClick={changeTime}
                layout={"horizontal"}
                margin={marginStyling}
                data={randomSet}>
                <XAxis dataKey={"time"} tick={false}
                    scale={"linear"}
                    type={"number"}
                    domain={[0, currentVisitors.length - 1]}
                    tickCount={48}/>
                <Tooltip cursor={false} content={
                            <CustomTooltip time={currentTime} />} />
                <Bar height={25} dataKey={"Shopper"} stackId={0} barSize={15}
                    fill={"#D7970E"} shape={<CustomBar playTime={playTime} />}>
                </Bar>
                <Bar height={25} dataKey={"Pedestrian"} stackId={0}
                    fill={"#217EE2"} shape={<CustomBar playTime={playTime} />}>
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}


const SliderAnalytics = () => {
    return (
        <div className={classes.inner_box}>
            <div className={classes.layer}>
                <div className={`${classes.component_horizontal} ${classes.timeline_wrapper}`}>
                    {/*<CalendarSelection />*/}
                    <PlayPauseButton/>
                    <SliderUI>
                        <div style={{
                            width : "100%",
                            height : "50px"
                        }}>
                            <PedastrianShopper/>
                        </div>
                    </SliderUI>
                    <CurrentButton/>
                </div>
            </div>
        </div>
    );
};

export default SliderAnalytics;