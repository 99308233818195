import React, { memo, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {Switch, Route, Redirect} from 'react-router-dom';

import Home from '../Home';
import Analytics from '../Analytics-v2';

import TopNav from "../../components/TopNav";
import LeftSideNav from "../../components/LeftSideNav";
import "bootstrap/dist/css/bootstrap.min.css";

import classes from './dashboard.module.scss';
import {TimelineProvider, TimeManagerProvider, TrendDataProvider} from "../NewDashBoard/components/Context/TimeLine";

const Dashboard = memo(() => {

    const [loading, setLoading] = useState(true);

    localStorage.setItem('installedDate', '2021-07-26');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Container fluid className={classes.wrapper}>
            {/*loading stage*/}
            {loading ? (
                <div className={classes.loading_screen}>
                    <div className={classes.loader_wrapper}>
                        <img src={"SPACE_loading_screen.gif"} alt={"Loading..."} />
                    </div>
                </div>
            ) : null}
            {/*top navigation bar*/}
            <TopNav />
            {/*left navigation bar*/}
            <LeftSideNav />
            {/*main section*/}
            <TimeManagerProvider>
                <TimelineProvider>
                    <TrendDataProvider>
                        <Row className={classes.full_height}>
                            <Col className={classes.right_wrapper}>
                                <Switch>
                                    <Route exact path='/home' component={Home}/>
                                    <Route exact path='/analytics' component={Analytics}/>
                                    <Redirect from='/' to='/home' />
                                </Switch>
                            </Col>
                        </Row>
                    </TrendDataProvider>
                </TimelineProvider>
            </TimeManagerProvider>
        </Container>
    )
});

export default Dashboard;
