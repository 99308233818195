import classes from "./RangePicker.module.scss";
import Calendar from "react-calendar";

import dayjs from 'dayjs';
import React, {useState} from "react";

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

// End of import
// Arrows to change between months
const ControlLabel = ({children}) => {
    const controlStyling = {
        backgroundColor: 'black',
    };

    const controlLabelClass = [
        classes.calendar_navigation,
        classes.selection,
    ].join(' ');

    return (
        <div className={controlLabelClass} style={controlStyling}>
            {children}
        </div>
    );
}

// Navigation part (between 2 arrows)
const NavigationRender = ({label}) => {
    const navigationRenderStyling = {
        backgroundColor: 'black',
    };

    return (
        <div className={classes.calendar_navigation} style={navigationRenderStyling}>
            {label}
        </div>
    );
};
const checkMaximumDate = () => {
    // Get the current date base on the server's timezone.
    const timezone = localStorage.getItem('serverTimezone') ? localStorage.getItem('serverTimezone') : "Australia/Brisbane";
    // @ts-ignore
    const serverTime = dayjs(new Date()).tz(timezone);
    // Get the current date base on system's date
    let adjustedMaxTime = new Date();
    // If the server's date is 1 day ahead of system's date, add 1 day to system's date
    if (serverTime.date() > new Date().getDate()) {
        adjustedMaxTime.setDate(adjustedMaxTime.getDate() + 1);
    }
    adjustedMaxTime.setDate(adjustedMaxTime.getDate() - 1);

    return adjustedMaxTime;
}

export const RangePicker = (props) => {
    const visibility = props.visible;

    // date is an array in [year, month, date] format, from local storage
    const date = localStorage.getItem('installedDate').split("-");
    const installedDate = parseInt(date[2]);
    const installedMonth = parseInt(date[1]) - 1;
    const installedYear = parseInt(date[0]);

    // minimum date from installed date in local storage
    const defaultMinDate = dayjs(new Date()).month(installedMonth).date(installedDate).year(installedYear).toDate();
    // Default max date is today's date minus 1
    const defaultMaxDate = checkMaximumDate();

    // State to control the max and min date
    const [minDate, setMinDate] = useState(defaultMinDate);
    const [maxDate, setMaxDate] = useState(defaultMaxDate);
    const [selectedDay, setSelectedDay] = useState(new Date());

    // State to control the tileDisable
    const [disable, setDisable] = useState(false);
    const calendarClassList = [classes.calendar_relative];

    if (!visibility) {
        calendarClassList.push(classes.hide)
    }

    // Function to handle when a range is selected
    const onDateSelect = (date) => {
        const firstDay = dayjs(date[0]);
        const lastDay = dayjs(date[1]);

        // Set the current range
        props.setSelectedDate(date);
        // Close the calendar after finished selecting range
        props.setVisible(!props.visible);
        // Set useCalendar to true, aka the graph will use value from the calendar
        props.setUseCalendar(true);
        // Set the number of days for the graph
        lastDay.diff(firstDay, 'day')
        props.setDays(lastDay.diff(firstDay, 'day'));

        // Set the maximum and minimum date to default
        setMaxDate(defaultMaxDate);
        setMinDate(defaultMinDate);
        // Turn off tile disable
        setDisable(false);
    }

    // Function to handle when a date is clicked
    const onDateClick = (date) => {
        const firstDay = dayjs(date);
        // After the first day is clicked, turn on disable tile
        setDisable(true);
        // Set the selected Day to the first date in range
        setSelectedDay(date);
        // The 2nd date must be within 62 days before or after the 1st date.
        const tempMaxDay = firstDay.add(61, 'day').toDate() < defaultMaxDate ?
            firstDay.add(61, 'day').toDate()
            : defaultMaxDate;

        const tempMinDay = firstDay.subtract(61, 'day').toDate() > defaultMinDate ?
            firstDay.subtract(61, 'day').toDate()
            : defaultMinDate;

        setMaxDate(tempMaxDay);
        setMinDate(tempMinDay);
    }

    // Function to check if a date is within next or last 7 days
    // Used to disable the tile in the Select Range calendar, when 1 day of the range is selected
    const checkDisableDate = (date) => {

        // Converted the first selected day to dayjs
        const selectedDayState = dayjs(selectedDay);
        // The current date being check
        const currDate = dayjs(date);

        // If disable is true, proceed to check the condition
        if (disable) {
            const maxDay = selectedDayState.add(5, 'day');
            const minDay = selectedDayState.subtract(5, 'day')
            // If it's either between the selectedDay - 7 days later
            // Or 7 days before - selectedDay, return true
            return ((currDate.isBetween(selectedDayState.format('YYYY-MM-DD'), maxDay.format('YYYY-MM-DD'), null, '(]'))
                || (currDate.isBetween(minDay.format('YYYY-MM-DD'), selectedDayState.format('YYYY-MM-DD'), null, '[)'))
            )
        }

        return false;
    }
    return (
        <div className={calendarClassList.join(' ')}>
            <div className={classes.calendar_absolute}>
                <Calendar
                    className={classes.calendar}
                    onChange={onDateSelect}
                    onClickDay={onDateClick}
                    value={props.selectedDate}
                    selectRange={true}
                    next2Label={null}
                    prev2Label={null}
                    nextLabel={<ControlLabel>&gt;</ControlLabel>}
                    prevLabel={<ControlLabel>&lt;</ControlLabel>}
                    navigationLabel={props => <NavigationRender {...props} />}
                    minDate={minDate}
                    maxDate={maxDate}
                    tileClassName={classes.calendar_tiles}
                    tileDisabled={({date}) => checkDisableDate(date)}
                    allowPartialRange={false}
                />
            </div>
        </div>
    )
}