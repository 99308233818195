// noinspection JSUnresolvedFunction
import React from "react";
import {
    ComposedChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    Label,
    Bar,
    CartesianGrid,
    Line,
} from "recharts";

import { timeStringFormat, getTimeLabel } from "../functions/backtrackTime";

// Import dayJS module to convert time between timezones
import dayjs from "dayjs";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Function to compare 2 Date X and Y. Return True if they are the same date, False otherwise.
 * @param {*} X : The date of the selected day.
 * @param {*} Y : The current date
 * @returns  Boolean - True if X and Y is the same date, False otherwise
 */
const compareDate = (X, Y) => {
    // Set hours, minutes, seconds and milliseconds to 0
    const selectedDate = X.hour(0).minute(0).second(0).millisecond(0);
    // selectedDate = selectedDate.hour(0).minute(0).second(0).millisecond(0);
    const currentDate = Y.hour(0).minute(0).second(0).millisecond(0);
    return (selectedDate.diff(currentDate) === 0);
}

/**
 * Function to check if a number has decimal or not.
 * @param {number} X Number to be checked
 * @returns boolean
 */
const checkDecimal = (X) => {
    return (X - Math.floor(X)) !== 0;
}

const CustomTooltip = (props) => {
    if (!props || !props.payload) {
        return null;
    }

    let payload_bar;
    let payload_line;
    if (props.payload[0]) {
        payload_bar = props.payload[0].value;
        payload_line = props.payload[1].value;
    }

    return (
        <div
            style={{
                background: "rgba(0, 0, 0, 0.85)",
                padding: "1em",
                textAlign: "center",
                borderRadius: "1em"
            }}
        >
            <span>{props.label}</span>
            <br/>
            <span style={{color: "#D7970E"}}>
                {props.label_bar ? props.label_bar : "Total"}: {payload_bar}
            </span>
            <br/>
            <span style={{color: "rgb(54,147,215)"}}>
        {props.label_line ? props.label_line : "Value"}: {payload_line}
      </span>
        </div>
    );
};

const LineBarChart = ({lineData, barData, label_line, label_bar, noLabel}) => {
    // Get the date setting function
    const serverTZ = localStorage.getItem('serverTimezone') ? localStorage.getItem('serverTimezone') : "Australia/Brisbane";
    const marginStyling = {
        top: 15,
        left: 20,
        right: 20,
        bottom: 20,
    };

    const labelStyling = {
        textAnchor: "middle",
        fill: "rgb(255, 255, 255)",
        fontWeight: "bold",
        fontSize: "medium",
    };

    // Variables to hold length of the current line data and the content.
    let dataLength = 0;
    let lineArray = [];
    // Deconstruct the object into an array of values
    for (let key in lineData) {
        if (checkDecimal(lineData[key])) {
            lineArray.push(parseFloat(lineData[key].toFixed(4)));
        } else {
            lineArray.push(lineData[key])
        }
        // Get length of the data array
        dataLength += 1;
    }
    if (!lineArray || !dataLength) {
        return null;
    }

    // Variables to hold length of the current bar data and the content.
    dataLength = 0;
    let barArray = [];
    // Deconstruct the object into an array of values
    for (let key2 in barData) {
        barArray.push(barData[key2]);
        // Get length of the data array
        dataLength += 1;
    }
    if (!barData || !dataLength) {
        return null;
    }


    // Calculate the number of intervals for the chart
    const timezone = localStorage.getItem('serverTimezone') ? localStorage.getItem('serverTimezone') : "Australia/Brisbane";

    let todayTime = dayjs(new Date()).tz();
    let selectedDate = todayTime.hour(7).minute(0).second(0).millisecond(0);
    let residual = dayjs(new Date()).tz(timezone).minute() > 30 ? 0.5 : 0;
    let currTime = dayjs(new Date()).tz(timezone).hour();
    let maxInterval = compareDate(selectedDate, dayjs(new Date()).tz(timezone)) ? (currTime - 7 + residual) * 2 : 48;
    const graphInterval = Math.round(maxInterval / 5);

    const dataSet = [];

    for (let index = 0; index <= maxInterval; index++) {
        // Get the format time for each index
        const adjustedTime = getTimeLabel(index, dayjs().tz(timezone), timezone);
        const timeString = timeStringFormat(adjustedTime, "dayjs");

        dataSet.push({
            time: index,
            timeString: timeString,
            Line: lineArray[index],
            Bar: barArray[index]
        });
    }

    return (
        <div style={{width: "100%", height: "100%"}}>
            <ResponsiveContainer>
                <ComposedChart
                    margin={marginStyling}
                    data={dataSet}
                    width={700}
                    height={250}
                >
                    <defs>
                        <linearGradient id="LineBar_Bar_Gradient" x1="0%" y1="0%" x2="0%" y2="100%"
                                        gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0%" stopColor="#46310BB3"/>
                            <stop offset="50%" stopColor="#DF970E"/>
                        </linearGradient>
                        <linearGradient id="LineBar_Line_Gradient"
                                        x1="0%" y1="0%" x2="0%" y2="100%"
                        >
                            {/*Light Blue*/}
                            <stop offset="0%" stopColor="#217ee2"/>
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray={"3 3"} stroke={"rgba(76, 76, 76, 0.3)"}/>
                    <XAxis dataKey={"timeString"}
                           interval={graphInterval}
                           tick={true}
                           dy={15}
                    />

                    <YAxis
                        yAxisId="left"
                        tick={true}
                        tickCount={5}
                        domain={['dataMin', 'auto']}
                    >
                        <Label
                            position={"insideLeft"}
                            angle={-90}
                            style={noLabel ? {display: "none"} : labelStyling}
                        >
                            Number of People
                        </Label>
                    </YAxis>

                    <YAxis yAxisId="right"
                           orientation={"right"}
                           tick={true}
                           tickCount={5}
                           dataKey={"Line"}>
                        <Label
                            position={"insideLeft"}
                            angle={90}
                            dx={50}
                            style={noLabel ? {display: "none"} : labelStyling}
                        >
                            Average Time
                        </Label>
                    </YAxis>

                    <Bar yAxisId="left" dataKey="Bar" fill="url(#LineBar_Bar_Gradient)" name={label_bar}
                         fillOpacity={1}
                         radius={[10, 10, 0, 0]}/>
                    <Line yAxisId="right" dataKey="Line" stroke="url(#LineBar_Line_Gradient)" name={label_line}
                          strokeWidth={5}
                          radius={100}
                          dot={false}/>
                    <Tooltip content={<CustomTooltip timezone={serverTZ} label_line={label_line}
                                                     label_bar={label_bar}
                    />}/>
                </ComposedChart>
            </ResponsiveContainer>

        </div>
    );
};

export default LineBarChart;
