import React from "react";
import {Col, Navbar, Row} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../images/logo-white.png";
import SPACEanalyticsOnly from "../../images/SPACE-AnalyticsOnly.png";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import classes from './topnav.module.scss';

const TopNav = () => {
    const { trackEvent } = useMatomo()
    //Matomo Tracker
    const handleOnClick = () => {
        // Track click on button
        trackEvent({ category: 'Button-Clicks', action: 'book-demo', name: 'TypeForm'})
    }

    return (
        <Row className={classes.top_navbar}>
            <Col className={classes.top_navbar_left_side}>
                <div className={classes.logo_img}>
                    <img src={logo} alt="logo_image"/>
                </div>
                <div className={classes.logo_img_analytics}>
                    <img src={SPACEanalyticsOnly} alt="logo_image_2"/>
                </div>
            </Col>
            <Col className={classes.top_navbar_right_side_wrapper}>
                <Navbar className={classes.top_navbar_right_side}>
                    <div
                        className={classes.book_demo}
                        onClick={() => {
                        handleOnClick();
                        window.open("https://form.typeform.com/to/D2KY2hMv");
                    }}>
                        Book a Demo
                    </div>
                </Navbar>
            </Col>
        </Row>
    )
};

export default TopNav;