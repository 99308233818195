// TODO This file exists so that it won't affect
// already created HeatMap. In future this will be 
// merged with Heatmap.js
import React, { 
    createContext, useContext, 
    useState, useEffect,
    useRef
} from "react";
import { TrendData } from "./TimeLine";
import Random from "../../../../components/functions/Random";
import kdTree from "../../../../components/functions/2dTree";
import polygonComparator from "./functions/comparator";
import { data as location } from "./geoSet.json";

export const GeoHeatMapTimeline = createContext();
export const GeoHeatMapContext = ( { children } ) => {
    const { today } = useContext(TrendData);
    const { currentUsers } = today;
    const [query, setQuery] = useState(null);

    const database = useRef([]);

    useEffect(() => {
        currentUsers.data.forEach(users => {
            let record = [];
            location.forEach(( { map, polygon, name } ) => {
                let spatialIndexing = new kdTree();
                let aggregatedPoints = {
                    indexing : spatialIndexing,
                    map : map,
                    name : name,
                    min : 0,
                    max : 100,
                    data : []
                };
    
                let sortedPolygon = polygon.sort(polygonComparator);
                let remainingUsers = users;
                
                // For each polygon in the list
                for (let polygonIndex = 0; polygonIndex < sortedPolygon.length;
                     polygonIndex++) {
                    let { triangle : triangles, percentage} = sortedPolygon[polygonIndex];
                    let sortedTriangle = triangles.sort(polygonComparator);

                    for (let triangleIndex = 0; 
                        triangleIndex < sortedTriangle.length; 
                        triangleIndex++) {
                        let { percentage : trianglePercentage, points } = 
                                sortedTriangle[triangleIndex];
                        let distribution;

                        if (polygonIndex === sortedPolygon.length - 1 && 
                                    triangleIndex === sortedTriangle.length - 1) {
                            distribution = remainingUsers;
                        } else {
                            distribution = Math.round(users * percentage * 
                                trianglePercentage);
                        }

                        let randomSet = Random.generateWithinTriangle(points, distribution);

                        remainingUsers -= distribution;
                        randomSet.forEach(value => {
                            aggregatedPoints.data.push(value);
                        })
                    }

                }

                spatialIndexing.addPoints(
                    aggregatedPoints.data
                );

                record.push(aggregatedPoints);
            });

            database.current.push(record);

            setQuery({
                findHeatMapSet : function(time) {
                    if (time < 0 || time >= database.length) {
                        return [];
                    }
                    return database.current[time];
                }
            });

        });
    }, [currentUsers]);

    if (!query) {
        return null;
    }

    return (
        <GeoHeatMapTimeline.Provider value={ query }>
            { children }
        </GeoHeatMapTimeline.Provider>
    )
}