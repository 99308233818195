// Import dayJS module to convert time between timezones
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const backtrackTime = (playTime, maxTime, time) => {
    let currentTimeCloned = new Date(time);
    let minutes = currentTimeCloned.getMinutes();
    
    if (minutes < 30) {
        currentTimeCloned.setMinutes(0);
    } else {
        currentTimeCloned.setMinutes(30);
    }

    currentTimeCloned.setSeconds(0);

    return new Date(
        currentTimeCloned.getTime() + (playTime - maxTime) * 60 * 30 * 1000
    );
}

export const timeStringFormat = (time, format="noDayjs") => {
    let time2Hrs, time2Mins;
    if (format === "noDayjs") {
        time2Hrs = time.getHours();
        time2Mins = time.getMinutes();
    } else {
        time2Hrs = time.hour();
        time2Mins = time.minute();
    }

    let suffix = 'am';

    if (time2Hrs >= 12) {
        suffix = 'pm';
    }

    if (time2Hrs < 10 && !time2Hrs === 0) {
        time2Hrs = `0${time2Hrs}`;
    } else {
        time2Hrs = time2Hrs % 12;
        if (time2Hrs === 0) {
            time2Hrs = 12;
        }
    }

    if (time2Mins < 10) {
        time2Mins = `0${time2Mins}`;
    }

    return `${time2Hrs}:${time2Mins} ${suffix}`;
}

export const Month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

export const getTimeLabel = (currTimeInterval, currentTime, timezone) => {

    let currentTimeCloned = dayjs(new Date(currentTime)).tz(timezone);
    let labelHour = 7 + (currTimeInterval / 2);
    let labelMinute = labelHour % 1 === 0 ? 0 : 30;
    currentTimeCloned = currentTimeCloned.hour(labelHour).minute(labelMinute).second(0);

    return currentTimeCloned;
};

export default backtrackTime;