import React from "react";
import styles from "./DaySlider.module.scss"
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import dayjs from "dayjs";

// End of import

const CustomTooltip = (props) => {
    if (!props || !props.payload) {
        return null;
    }

    let total_visitors;

    if (props.payload[0]) {
        total_visitors = props.payload[0].value;
    }

    return (
        <div
            style={{
                background: "rgba(0, 0, 0, 0.85)",
                padding: "1em",
                textAlign: "center",
                borderRadius: "1em"
            }}
        >
            <span style={{color: "#fff", fontWeight: "bold"}}>
                Date: {props.label}
            </span>
            <br/>
            <span style={{color: "#777", fontWeight: "bold"}}>
                Total Visitors: {total_visitors}
            </span>
            {/* <br/>
            <span style={{color: "rgb(255, 183, 3)", fontWeight: "bold"}}>
                Pedestrian: {pedestrian}
            </span>
            <br/>
            <span style={{color: "rgb(33, 126, 226)", fontWeight: "bold"}}>
                Shoppers: {shoppers}
            </span> */}

        </div>
    );
};

//Styling for chart
const marginStyling = {
    top: 15,
    left: 20,
    right: 50,
    bottom: 20,
};

// Styling for the X-axis label
const XAxisStyling = {
    textAnchor: "middle",
    fill: "#D7970E",
    fontWeight: "bold",
    fontSize: "medium",
    paddingBottom: "2% !important",
    marginBottom: "2% !important"
};
// End of styling

const legendFormatter = (value) => {
    const legendStyling = {
        marginTop: "2em !important",
        paddingTop: "2em !important",
        fontWeight: "900",
        fontSize: "1em",
    };

    return (
       <span style={legendStyling}>{value}</span>
    )

}
const CustomLabel = (props) => {
    const {x, y, value} = props;

    return (
        <text x={x} y={y} fill="white" fontWeight="900" offset="5" textAnchor="start"
              style={{textShadow: "2px 2px 3px black", marginRight: "1%"}}
        >
            <tspan x={x} dy="2em" dx="-0.1em">{value}</tspan>
        </text>
    )
}

/**
 * Get the data according to the date selected on the calendar
 * @param selectedDate[Date,Date]: An array with 2 Date, the first and second selected date
 * @param dataArray: The array contains all data
 * @returns {*} : The dataset according to the selected date
 */
const getData = (selectedDate, dataArray) => {
    const firstDay = dayjs(selectedDate[0]).format('DD-MM');
    const firstDayPos = dataArray.findIndex(element => element.date === firstDay);
    const lastDay = dayjs(selectedDate[1]).format('DD-MM');
    const lastDayPos = dataArray.findIndex(element => element.date === lastDay);
    return dataArray.slice(firstDayPos, lastDayPos + 1);
}

const DayTimeline = ({numberOfDays, calendarData, dataLength, selectedDate, useCalendar}) => {

    let dataArray = calendarData;
    // If data is empty return null
    if (!dataArray || !dataLength) {
        return null;
    }

    let dataSet;
    let maxInterval; // The number of intervals on the graph

    // If the user used the calendar to get the range
    if (useCalendar) {
        dataSet = getData(selectedDate, dataArray);
        maxInterval = dataSet.length;
    } else {
        maxInterval = Math.min(dataLength, numberOfDays);
        dataSet = dataArray.slice(-maxInterval);
    }
    let averageVisitors = 0;
    Object.values(dataSet).forEach(value => {
            averageVisitors += value.value;
        }
    )
    averageVisitors = averageVisitors / maxInterval;

    const fillOpa = 0.15;
    return (
        <div className={styles.chart_container} id="dayslider_card">
            <ResponsiveContainer width={'99%'} height={400}>
                <ComposedChart
                    margin={marginStyling}
                    data={dataSet}
                    style={{position: "absolute"}}
                >
                    <defs>
                        <linearGradient id="LineGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="#DF970E"/>
                            <stop offset="50%" stopColor="#777"/>
                        </linearGradient>
                        <linearGradient id="LineGradientYellow" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="#DF970E"/>
                            <stop offset="50%" stopColor="#46310B"/>
                        </linearGradient>
                        <linearGradient id="LineGradientBlue" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="#217EE2"/>
                            <stop offset="60%" stopColor="#2C5683"/>
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray={"3 3"} stroke={"rgba(76, 76, 76, 0.3)"}/>
                    {numberOfDays <= 20 ? <XAxis dataKey={"date"}
                                                 interval={0} tick={true}
                                                 style={XAxisStyling}
                                                 dy={15}
                    /> : <XAxis dataKey={"date"}
                                interval={Math.round(numberOfDays / 4)} tick={true}
                                style={XAxisStyling}
                                dy={15}
                    />}

                    <YAxis type="number" tick={true} domain={["auto", "dataMax"]}
                    />

                    <ReferenceLine
                        name="Average Line"
                        y={averageVisitors}
                        stroke="#D7970ECC"
                        opacity={0.4}
                        strokeWidth="4"
                        strokeDasharray="9 9"
                        label={{fill: '#D7970E', position: 'left'}}
                    />
                    {/*A dummy line represents the average line*/}

                    <Line dataKey={"null123"}
                          name={"Average"} stroke={"#D7970E"}
                          strokeWidth="4"
                          opacity={0.2} strokeDasharray="9 9"/>
                    {numberOfDays <= 20 ? <Area dataKey="value"
                                                label={<CustomLabel/>}
                                                type="monotone"
                                                name={"Total Visitors"}
                                                stroke="url(#LineGradient)"
                                                strokeWidth={9}
                                                isAnimationActive={false}
                                                fillOpacity={fillOpa}
                                                fill="url(#LineGradientYellow)"

                        />
                        :
                        <Area dataKey="value"
                              type="monotone"
                              name={"Total Visitors"}
                              stroke="url(#LineGradient)"
                              strokeWidth={9}
                              isAnimationActive={false}
                              fillOpacity={fillOpa}
                              fill="url(#LineGradientYellow)"
                        />
                    }

                    <Tooltip content={<CustomTooltip/>} cursor={{fill: 'transparent'}}/>

                    <Legend
                        wrapperStyle={{
                            paddingTop: "1em",
                            position: 'sticky'
                        }}
                        payload={
                            [
                                {id: 'Average', value: 'Average', type: 'diamond', color: '#D7970E'},
                                {id: 'TotalVisitors', value: 'Total Visitors', type: 'diamond', color: '#777'},

                            ]
                        }
                        formatter={legendFormatter}
                    />
                </ComposedChart>

            </ResponsiveContainer>
        </div>
    );


};

export default DayTimeline;