// noinspection TypeScriptValidateTypes,JSUnresolvedFunction,JSUnresolvedVariable
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
// @ts-ignore
import html2canvas from "html2canvas";
import dayjs from "dayjs";

/**
 * This document generates PDF file only when required
 */
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const DAY_MAPPING = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
};

const processData = (data) => {
    const result = []
    for (let index = 0; index < data.length; index++) {
        const {fullDate, value} = data[index];
        const dayOfWeek = dayjs(fullDate).day();
        const formattedDate = dayjs(fullDate).format("DD-MM-YY");

        result.push({
            fullDate: formattedDate,
            dayOfWeek: DAY_MAPPING[dayOfWeek],
            value: value,
            // pedestrian: value,
            // shopper: value,
        })
    }
    return result;
}

const filterData = (processedData, dateRange) => {
    const firstDay = dayjs(dateRange[0]).unix();
    const lastDay = dayjs(dateRange[1]).unix();
    return processedData.filter(el => (
        dayjs(el.fullDate, "DD-MM-YY").unix() >= firstDay && dayjs(el.fullDate, "DD-MM-YY").unix() <= lastDay)
    );
}

const getLastNDays = (processedData, numbDays) => {
    return processedData.slice([-numbDays])
}

const generateNewPDF = async (useCalendar, data, selectedDate, numbDays) => {
    // The pdf document
    // noinspection JSPotentiallyInvalidConstructorUsage
    const doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "a3",
        hotfixes: "px_scaling"
    });

    // Get the exported page's width
    let pageWidth = doc.internal.pageSize.width;
    // Process the data and get the values
    const dateLabels = processData(data);

    const finalData = useCalendar ? filterData(dateLabels, selectedDate) : getLastNDays(dateLabels, numbDays);
    let averageVisitors = 0;
    Object.values(finalData).forEach(value => {
            averageVisitors += value.value;
        }
    )
    averageVisitors = averageVisitors / finalData.length;
    // Array holding all values for the table
    const tableValues = finalData.map(ele => [ele.fullDate, ele.dayOfWeek, ele.value])

    // Render the SPACE logo and Exported time
    let logoImg = new Image();
    logoImg.src = 'SPACE_analytics_logo.png'
    doc.addImage(logoImg, 'PNG', 380, 10, 100, 78.8, '', 'NONE');
    doc.text(`Export Date: ${new Date().toLocaleDateString()}`, 720, 20);
    doc.text(`Export Time : ${new Date().toLocaleTimeString()}`, 720, 35);

    // Get the graph
    const dailyGraph =
        document.getElementById("dayslider_card");

    const canvas = html2canvas(dailyGraph);
    let imgData = (await canvas).toDataURL('image/png')
    const imgProps = doc.getImageProperties(imgData)
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    // Get the Y-coordinate to render the graph
    const dailyGraph_Y = 80;
    doc.addImage(imgData, 'PNG', 0, dailyGraph_Y, pdfWidth, pdfHeight, '', 'NONE')

    // Get the number of days for each table
    const actualNumbDay = useCalendar ? Math.min((numbDays + 1), 62) : numbDays;
    const tableNumber = Math.max(Math.ceil(actualNumbDay / 16), 1);
    // Center of the page
    const pageCenter = pageWidth / 2;
    // Calculating the start point of the first table
    const startX = tableNumber % 2 === 0 ? pageCenter - 10 - (20 * ((tableNumber / 2) - 1)) - (150 * (tableNumber / 2))
        : (pageCenter - 75) - (20 * (tableNumber - 2)) - (150 * (tableNumber - 2));
    // Calculate the number of rows
    const maxRows = Math.ceil(actualNumbDay / tableNumber);

    let startIndex = 0;
    let endIndex = startIndex + maxRows;

    let tableX = (numbDays + 1) <= 16 ? 371 : 10 + startX;
    // Render the table data
    for (let i = 0; i < tableNumber; i++) {
        autoTable(doc, {
            head: [['Date', 'Day of Week', 'Unique Visitors']],
            // head: [['Date', 'Day of Week', 'Unique Visitors', 'Pedestrian', 'Shopper']],
            // @ts-ignore
            body: tableValues.slice(startIndex, endIndex),
            theme: "grid",
            headStyles: {
                fillColor: "#7a7a7a",
            },
            // eslint-disable-next-line no-loop-func
            didParseCell: (data) => {
                if (data.cell.text[0] === "Saturday" || data.cell.text[0] === "Sunday") {
                    data.cell.styles.textColor = "black";
                    data.cell.styles.fillColor = "#c9c9c9";
                    data.cell.styles.fontStyle = "bold";
                } else {
                    // Fill background if the value is greater than average
                    const parsedValue = parseInt(data.cell.text[0]);
                    if (!isNaN(parsedValue)) {
                        if (parsedValue >= averageVisitors) {
                            data.cell.styles.textColor = "black";
                            data.cell.styles.fillColor = "#df970e";
                            data.cell.styles.fontStyle = "bold";
                        }
                    }
                }
            },
            columnStyles: {
                0: {cellWidth: 50},
                1: {cellWidth: 50},
                2: {cellWidth: 50},
                // 3: {cellWidth: 50},
                // 4: {cellWidth: 50},

            },
            // Render the table directly below the graph
            startY: pdfHeight + 20 + dailyGraph_Y,
            margin: {left: tableX},
            styles: {
                halign: "center",
                lineColor: [148, 148, 148],
                lineWidth: 0.3,
            }
        });
        tableX += 170;
        startIndex += maxRows;
        endIndex = startIndex + maxRows;
    }

    // Export as a pdf file.
    // doc.save(`Daily Data - ${actualNumbDay} Days.pdf`)
    doc.save(`Daily Data - ${new Date().getTime()}.pdf`);
}

export default generateNewPDF;