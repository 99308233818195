import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";

import SliderAnalytics from "../NewDashBoard/components/SliderAnalytics/SliderAnalytics";
import AreaCards from '../../components/ChartCards/AreaCards';

import classes from './analytics.module.scss';
import { useMatomo } from '@datapunt/matomo-tracker-react'

const Analytics = () => {

	const { trackPageView } = useMatomo()
	useEffect(() => {
    trackPageView()
      }, [trackPageView])
    // End of Update section.

    return (
        <div className={classes.scrollable}>
            <Helmet>
                <title>SPACE Dashboard - Home</title>
            </Helmet>
            <div className={classes.today_data_wrapper}>
                <div className={classes.today_data}>
                    <div className={classes.slider}>
                        <SliderAnalytics />
                    </div>
                </div>
                <div className={classes.statistics_wrapper}>
                    <AreaCards />
                </div>
            </div>
        </div>
    )
};

export default Analytics;
