import 'react-app-polyfill/ie11';
import 'react-app-polyfill/jsdom';
import 'react-app-polyfill/stable';
import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

const instance = createInstance({
  urlBase: 'https://webanalytics.spaceplatform.pro/',
  siteId: 2,
  // userId: 'UID76903203', // optional, default value: `undefined`.
  // trackerUrl: '//localhost:8085/tracking.php', // optional, default value: `${urlBase}matomo.php`
  // srcUrl: '//localhost:8085/tracking.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default  
    active: true, // optional, default value: true
    seconds: 15 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  // configurations: { // optional, default value: {}
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

render(
  <Router basename={process.env.PUBLIC_URL}>
    <MatomoProvider value={instance}>
    <App />
    </MatomoProvider>
  </Router>,
  document.getElementById("root")
);


